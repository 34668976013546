import React from "react";
import {
  Typography,
  MenuItem,
  List,
  Toolbar,
  CssBaseline,
  Box,
  useMediaQuery,
  SwipeableDrawer,
  TextField,
  InputAdornment,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import styles from "../styles/Header.module.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Carousal from "./Home-page/Carousal";
import { FormattedMessage } from "react-intl";
import Button from "@mui/material/Button";

const Header = (props) => {
  const imageDark = require.context(
    "../../../public/assets/images/dtlogoDark",
    false,
    /\.svg$/
  );
  const imageDataDark = {};

  imageDark.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.svg$/, "$1");
    imageDataDark[langCode] = imageDark(key);
  });
  const imageWhite = require.context(
    "../../../public/assets/images/dtlogoWhite",
    false,
    /\.svg$/
  );
  const imageDataWhite = {};

  imageWhite.keys().forEach((key) => {
    const langCode = key.replace(/^\.\/(.+)\.svg$/, "$1");
    imageDataWhite[langCode] = imageWhite(key);
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [loadMore, setLoadMore] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [inputField, setInputField] = React.useState(false);
  const location = useLocation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const lang = params.get("lang") !== null ? params.get("lang") : "en";
  console.log(lang, "language");

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleMenu = (event) => {
    setLoadMore(event.currentTarget);
    setOpenMenu((prev) => !prev);
    if (state.right) {
      const styles = {
        header_banner_bg: {
          backgroundColor: "black",
        },
      };
    }
    setState((prev) => {
      return { ...prev, right: !prev.right };
    });
    if (state.right === false) {
      props.setDarkBackground(true);
    }
  };

  const handleInputOpen = () => {
    setInputField(true);
  };

  const handleInputClose = () => {
    setInputField(false);
  };

  const handleClose = () => {
    setLoadMore(null);
  };

  React.useEffect(() => {}, [openMenu, loadMore]);

  // let prevScrollpos = window.pageYOffset;
  // window.onscroll = function () {
  //   let currentScrollPos = window.pageYOffset;
  //   if (prevScrollpos > currentScrollPos) {
  //     document.getElementById("main-header").style.top = "0";
  //   } else {
  //     document.getElementById("main-header").style.top = "-85px";
  //   }
  //   prevScrollpos = currentScrollPos;
  // };

  const list = (anchor) => (
    <Box
      sx={{ width: "auto", backgroundColor: "black", fontSize: { xs: "16px" } }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      onClick={handleMenu}
    >
      <List sx={{ width: "100%" }}>
        <MenuItem onClick={handleClose}>
          <Link
            className={styles["nav-a"]}
            New
            Message
            to={{
              pathname: "/aboutus",
              search: `?lang=${lang}`,
            }}
          >
            <FormattedMessage id="about_us_header" />
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            className={styles["nav-a"]}
            New
            Message
            to={{
              pathname: "/services",
              search: `?lang=${lang}`,
            }}
          >
            <FormattedMessage id="services_header" />
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            className={styles["nav-a"]}
            New
            Message
            to={{
              pathname: "/aiservices",
              search: `?lang=${lang}`,
            }}
          >
            <FormattedMessage id="ai_services_header" />
          </Link>
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <Link
            className={styles["nav-a"]}
            New
            Message
            to={{
              pathname: "/aiproducts",
              search: `?lang=${lang}`,
            }}
          >
            <FormattedMessage id="ai_products_header" />
          </Link>
        </MenuItem> */}
        <MenuItem onClick={handleClose}>
          <Link
            className={styles["nav-a"]}
            New
            Message
            to={{
              pathname: "/industries",
              search: `?lang=${lang}`,
            }}
          >
            <FormattedMessage id="industries_header" />
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            className={styles["nav-a"]}
            New
            Message
            to={{
              pathname: "/casestudies",
              search: `?lang=${lang}`,
            }}
          >
            <FormattedMessage id="case_studies_header" />
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link
            className={styles["nav-a"]}
            New
            Message
            to={{
              pathname: "/careers",
              search: `?lang=${lang}`,
            }}
          >
            <FormattedMessage id="careers_header" />
          </Link>
        </MenuItem>
        <Select
          disableUnderline
          variant="standard"
          value={props.currentLanguage}
          onChange={(e) => {
            props.setCurrentLanguage(e.target.value);
            window.location.search = `lang=${e.target.value}`;
          }}
          sx={{
            backgroundColor: "black",
            color: "#FBFBFB",

            height: "max-content",
            "& .MuiMenuItem-root": {
              backgroundColor: "black",
              color: "white",
            },
            ...(isMobile && {}),
          }}
          displayEmpty
          IconComponent={ExpandMoreIcon}
          inputProps={{ "aria-label": "Without label" }}
          style={{
            textAlign: "center",
            padding: "12px 0px 10px",
            lineHeight: "89px",
            fontSize: isMobile ? "35px" : "80px",
            marginBottom: "30%",
            marginLeft: isMobile ? "85%" : "90%",
            color: "#F8F8F8",
            fontFamily: "goudy-old-style, Georgia, Serif",
          }}
        >
          <MenuItem value="en">EN</MenuItem>
          <MenuItem value="es">ES</MenuItem>
        </Select>
      </List>
    </Box>
  );

  const items = {
    id: 1,
    img1: "/assets/images/navbar/imgbanner.png",
    img2: "/assets/images/navbar/for_mobile_banner.png",
    description: [
      "We work with companies globally in every industry",
      <br />,
      <span style={{ color: "#fffff0" }}>
        {" "}
        every sector and every size from startups to biggest brands
      </span>,
    ],
  };

  return (
    <>
      <CssBaseline />
      <div
        id="header"
        className={state.right ? styles.header_menu : styles.header_banner_bg}
      >
        <div className={styles.carousel_container}>
          <div className={styles.header_carousel_container}>
            <div className={styles.header_content}>
              <Toolbar className={styles["header-toolbar"]}>
                <Typography
                  className={styles["nav-logo"]}
                  variant="h4"
                  gutterBottom
                  component="div"
                >
                  <Link
                    className={styles["nav-title"]}
                    to={{
                      pathname: "/",
                      search: `?lang=${lang}`,
                    }}
                  >
                    <img
                      src={
                        // (props.darkBackground === false)
                        //   ? process.env.PUBLIC_URL +
                        //   "/assets/images/Original Logo-03.svg"
                        //   : process.env.PUBLIC_URL +
                        //   "/assets/images/Original Logo-04.svg"
                        // isMobile && state.right
                        //   ? imageDataWhite[props.currentLanguage]
                        imageDataWhite[props.currentLanguage]
                      }
                      alt="logo"
                      className={
                        props.currentLanguage === "en"
                          ? styles.logo_svg_en
                          : styles.logo_svg_es
                      }
                    />
                  </Link>
                </Typography>
                <>
                  {state.right ? (
                    <Button
                      sx={{
                        color: "white",
                        fontSize: "0.75rem",
                        fontWeight: "medium",
                        backgroundColor: "#000",
                        borderRadius: "999px",
                        lineHeight: "2",
                        marginRight: "2%",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        border: "1px solid white",
                      }}
                      onClick={handleMenu}
                      className={
                        !state.right
                          ? styles["more-vertical"]
                          : styles["more-verticals"]
                      }
                    >
                      CLOSE
                    </Button>
                  ) : (
                    // <MenuIcon
                    //   onClick={handleMenu}
                    //   className={
                    //     !state.right
                    //       ? styles["more-vertical"]
                    //       : styles["more-verticals"]
                    //   }
                    // />
                    <div
                      onClick={handleMenu}
                      className={
                        !state.left
                          ? styles["more-vertical_menu"]
                          : styles["more-verticals_menu"]
                      }
                    >
                      <Button
                        sx={{
                          color: "white",
                          fontSize: "0.75rem",
                          fontWeight: "medium",
                          backgroundColor: "#000",
                          borderRadius: "999px",
                          padding: "0.25rem 1rem",
                          lineHeight: "2",
                          paddingLeft: "25px",
                          paddingRight: "25px",
                          border: isMobile
                            ? "1px solid white"
                            : "1px solid black",
                          "&:hover": {
                            backgroundColor: "#000",
                            fontWeight: "600",
                          },
                        }}
                      >
                        MENU
                      </Button>
                    </div>
                  )}
                  <SwipeableDrawer
                    anchor={"right"}
                    open={state["right"]}
                    onClose={toggleDrawer("right", false)}
                    onOpen={toggleDrawer("right", true)}
                    className="main-container"
                  >
                    {list("left")}
                  </SwipeableDrawer>
                </>
                {/* {isMobile ? (
                ) : (
                  <div
                    className={
                      location.pathname === "/"
                        ? styles["header-options"]
                        : styles["header-option"]
                    }
                  >
                    <NavLink
                      activeClassName={styles["nav-a-active"]}
                      className={styles["nav-a nav-heading"]}
                      to={{
                        pathname: "/aboutus",
                        search: `?lang=${lang}`,
                      }}
                    >
                      <FormattedMessage id="about_us_header_desktop" />
                    </NavLink>
                    <NavLink
                      activeClassName={styles["nav-a-active"]}
                      className={styles["nav-a nav-heading"]}
                      to={{
                        pathname: "/services",
                        search: `?lang=${lang}`,
                      }}
                    >
                      <FormattedMessage id="services_header_desktop" />
                    </NavLink>
                    <NavLink
                      activeClassName={styles["nav-a-active"]}
                      className={"nav-a nav-heading"}
                      to={{
                        pathname: "/industries",
                        search: `?lang=${lang}`,
                      }}
                    >
                      <FormattedMessage id="industries_header_desktop" />
                    </NavLink>
                    <NavLink
                      activeClassName={styles["nav-a-active"]}
                      className={styles["nav-a nav-heading"]}
                      to={{
                        pathname: "/casestudies",
                        search: `?lang=${lang}`,
                      }}
                    >
                      <FormattedMessage id="case_studies_header_desktop" />
                    </NavLink>
                    <NavLink
                      activeClassName={styles["nav-a-active"]}
                      className={styles["nav-a nav-heading"]}
                      to={{
                        pathname: "/careers",
                        search: `?lang=${lang}`,
                      }}
                    >
                      <FormattedMessage id="careers_header_desktop" />
                    </NavLink>
                    {inputField ? (
                      <>
                        <TextField
                          id="outlined-start-adornment"
                          sx={{ mt: "-0.5%", width: "25ch", ml: "5rem" }}
                          variant="standard"
                          placeholder="Search"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CloseIcon
                          onClick={handleInputClose}
                          sx={{ color: "grey", mt: "-0.5%" }}
                        />
                      </>
                    ) : (
                      <SearchIcon
                        onClick={handleInputOpen}
                        sx={{ color: "#121212" }}
                      />
                    )}
                    <FormControl
                      sx={{
                        m: 0,
                        ml: "2rem",
                        minWidth: 50,
                        mt: "-0.5%",
                        backgroundColor: "Black !important",
                      }}
                    >
                      <Select
                        // disabled
                        disableUnderline
                        variant="standard"
                        value={props.currentLanguage}
                        onChange={(e) => {
                          props.setCurrentLanguage(e.target.value);
                          window.location.search = `lang=${e.target.value}`;
                          console.log(window.location.search);
                        }}
                        sx={{ backgroundColor: "black !important", color: "white" }}
                        displayEmpty
                        IconComponent={ExpandMoreIcon}
                        inputProps={{ "aria-label": "Without label", }}
                      >
                        <MenuItem
                          value="en"
                          sx={{ backgroundColor: "black !important", color: "white" }}
                        >
                          <img
                            width="30px"
                            style={{ paddingRight: "5px" }}
                            // src="/assets/images/flags/english.jpg"
                            alt="english"
                          />
                          EN
                        </MenuItem>
                        <MenuItem value="es" sx={{ backgroundColor: "black !important", color: "white" }}>
                          {/* <img
                            width="30px"
                            style={{ paddingRight: "5px" }}
                           src="/assets/images/flags/spain.png"
                            alt="espanola"
                          />
                          ES
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )} */}
              </Toolbar>
            </div>
            {location && location.pathname === "/" && !state.right ? (
              <div className={styles.carousel_content}>
                <Carousal
                  darkBackground={props.darkBackground}
                  setDarkBackground={props.setDarkBackground}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
