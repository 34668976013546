export const careerSec1 = [
  {
    img: "/assets/images/career_images/LifeatDT(1).svg",
    subTitle: "Life at DT",
  },
  {
    img: "/assets/images/career_images/Benefits(1).svg",
    subTitle: "Perks & Benefits",
  },
  {
    img: "/assets/images/career_images/Recruitment(1).svg",
    subTitle: "Recruitement Process",
  },
  {
    img: "/assets/images/career_images/OpenPositions(1).svg",
    subTitle: "Open Positions",
  },
  {
    img: "/assets/images/career_images/Locations(3).svg",
    subTitle: "Locations",
  },
  {
    img: "/assets/images/career_images/ReachOutToUs(1).svg",
    subTitle: "Reach Out To Us",
  },
];

export const careerSec2 = [
  {
    img: "/assets/images/career_images/Rectangle 290.svg",
    title: "What we look for",
  },
  {
    img: "/assets/images/career_images/Rectangle 290.svg",
    title: "Sustainability",
  },
  {
    img: "/assets/images/career_images/Rectangle 290.svg",
    title: "Working at Data Template",
  },
  {
    img: "/assets/images/career_images/Rectangle 290.svg",
    title: "Privacy Policy",
  },
];

export const expertise = [
  {
    img: "/assets/images/industries/Healthcare.svg",
    title: "Healthcare",
    industryType: "Healthcare",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
  {
    img: "/assets/images/industries/Finance.svg",
    title: "Finance, Mortgage & Real Estate",
    industryType: "Finance, Mortgage & Real Estate",
    color: "#ffffff",
    button_background: "#ffffff",
    button_color: "#000000",
  },
  {
    img: "/assets/images/industries/Satellite.svg",
    title: "Satellite & Telecom",
    industryType: "Satellite & Telecom",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
  {
    img: "/assets/images/industries/Manufacturing.svg",
    title: "Manufacturing",
    industryType: "Manufacturing",
    color: "#ffffff",
    button_background: "#ffffff",
    button_color: "#000000",
  },
  {
    img: "/assets/images/industries/Government.svg",
    title: "Government",
    industryType: "Healthcare Government",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
  {
    img: "/assets/images/industries/Education.svg",
    title: "Education",
    industryType: "Education",
    color: "#ffffff",
    button_background: "#ffffff",
    button_color: "#000000",
  },
  {
    img: "/assets/images/industries/Aerospace.svg",
    title: "Aerospace & Defence",
    industryType: "Aerospace & Defense",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
  {
    img: "/assets/images/industries/Retail.svg",
    title: "Retail",
    industryType: "Retail",
    color: "#ffffff",
    button_background: "#ffffff",
    button_color: "#000000",
  },
  {
    img: "/assets/images/industries/Travel-transportation.svg",
    title: "Travel & Transport Logistics",
    industryType: "Travel & Transport Logistics",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
  {
    img: "/assets/images/industries/Non-profit.svg",
    title: "Non - Profit Organization",
    industryType: "Healthcare Non- Profit Organization",
    color: "#ffffff",
    button_background: "#ffffff",
    button_color: "#000000",
  },
  {
    img: "/assets/images/industries/Hospitality.svg",
    title: "Hospitality & Leisure",
    industryType: "Hospitality & Leisure",
    color: "#ffffff",
    button_background: "#000000",
    button_color: "#ffffff",
  },
];

export const about = [
  {
    id: "founder",
    img: "/assets/images/aboutus/anil.svg",
    title: "Founder & CEO",
    subTitle: "Anil Kumar Parakkad",
  },
  {
    id: "finance",
    img: "/assets/images/aboutus/rajesh.svg",
    title: "Co-Founder & VP Finance and Operations",
    subTitle: "Rajesh Koyakkeel",
  },

  {
    id: "tech",
    img: "/assets/images/aboutus/sreejesh.svg",
    title: "Co-Founder & VP Opensource",
    subTitle: "Sreejesh Kumar",
  },
  {
    id: "techno",
    img: "/assets/images/aboutus/anoop.svg",
    title: "Co-Founder & VP Microsoft Technologies",
    subTitle: "Anoop Wellington",
  },
  {
    id: "business",
    img: "/assets/images/aboutus/dhaneshupdate.svg",
    title: "VP Business Development & Client Relationship",
    subTitle: "Dhanesh Menon",
  },
  {
    id: "operations",
    img: "/assets/images/aboutus/sukeshnew.svg",
    title: "VP U.S Operations",
    subTitle: "Sukesh Vadake Illam",
  },
];

export const serviceimg = [
  {
    id: "AD",
    serviceType: "Application Development",
    img: "/assets/images/services/applicationdevelopment.svg",
    title: "Application Development",
    subTitle: "Technology Expertise",
    subTitle2:
      "Angular New, Django, Dot Net, Ext-Js, Go, Java, Lamp, Microsoft SQL- Server, My SQL, Node Js, Oracle Database, PHP, Python, Rails, React",
  },
  {
    id: "BI",
    serviceType: "Business Intelligence & Big Data Analysis",
    img: "/assets/images/services/businessintelligence.svg",
    title: "Business Intelligence & Big Data Analysis",
    subTitle: "Technology Expertise",
    subTitle2:
      "DB, Grafana, Graphite, Hadoop, Informatica,Julia,Kafka, Kirbana, Power-BI, Spark, Tableau, Yardi",
  },
  {
    id: "CD",
    serviceType: "Cloud & DevOps",
    img: "/assets/images/services/devops.svg",
    title: "Cloud & DevOps",
    subTitle: "Technology Expertise",
    subTitle2:
      "Amazon Web Services, Cloudify, Firebase, Google-Cloud, Microsoft Azure, Microsoft System Center, Oen Stack-Cloud Software, Vmware",
  },
  {
    id: "Mobility",
    serviceType: "Mobility",
    img: "/assets/images/services/mobility.svg",
    title: "Mobility",
    subTitle: "Technology Expertise",
    subTitle2:
      "Android, Apple, Flutter, Kotlin, Phone Gap,React-Native, Titanium, USSD",
  },
  {
    id: "SI",
    serviceType: "Service Intergration",
    img: "/assets/images/services/serviceintegration.svg",
    title: "Service Integration",
    subTitle: "Technology Expertise",
    subTitle2:
      "Apache- Airflow, Carz-insp, Drools, Equifax, FedEx, FlexRule, Hive, Mulesoft, Skye- Closings, Twilio",
  },
  {
    id: "ST",
    serviceType: "Software Testing",
    img: "/assets/images/services/softwaretesting.svg",
    title: "Software Testing",
    subTitle: "Technology Expertise",
    subTitle2:
      "Asana, Jira Software, Jmeter, Katalon, Lamada Test, Mantis Bug Tracker, SE, SoapUI Pro, Testing, Test Link, Test Lodge",
  },
  {
    id: "IOT",
    serviceType: "Embedded & IoT",
    img: "/assets/images/services/embedded.svg",
    title: "Embedded & IoT",
    subTitle: "Technology Expertise",
    subTitle2:
      "3G, C++, Cisco, Embedded-c, Java, Kaa, LTE, Nokia, Python, Tellabs, Wimax",
  },
  {
    id: "ML",
    serviceType: "Machine Learning & Artificial Intelligence",
    img: "/assets/images/services/machinelearning.svg",
    title: "Machine Learning & Artificial Intelligence",
    subTitle: "Technology Expertise",
    subTitle2: "Jupyter, Pandas, Plotiy, R, Timeseries, Web Scrapping",
  },
  {
    id: "nft",
    serviceType: "NFT & Blockchain",
    img: "/assets/images/services/nft.svg",
    title: "NFT & Blockchain",
    subTitle: "Technology Expertise",
    subTitle2: "C++, Kaa, Python, Unity",
  },
  {
    id: "AR",
    serviceType: "Augmented Reality & Virtual Reality",
    img: "/assets/images/services/reality.svg",
    title: "Augmented Reality & Virtual Reality",
    subTitle: "Technology Expertise",
    subTitle2: "C++, Kaa, Python, Unity",
  },
  // {
  //   id: "AR",
  //   serviceType:'Cyber Security',
  //   img:"/assets/images/services/cybersecurity.svg",
  //   title: "Cyber Security",
  //   subTitle:"Technology Expertise",
  //   subTitle2: "C++, Kaa, Python, Unity"
  // }
];
export const serviceFooterimg = [
  {
    id: "AD",
    title: "Application Development",
  },
  {
    id: "BI",
    title: "BI & Big Data Analytics",
  },
  {
    id: "CD",
    title: "Cloud & DevOps",
  },
  {
    id: "Mobility",
    title: "Mobility",
  },
  {
    id: "SI",
    title: "Service Integration",
  },
  {
    id: "ST",
    title: "Software Testing",
  },
  {
    id: "IOT",
    title: "Embedded & IoT",
  },
  {
    id: "ML",
    title: "ML & AI",
  },
  {
    id: "nft",
    title: "NFT & Blockchain",
  },
  {
    id: "AR",
    title: "AR & VR",
  },
];

export const clientimgs = [
  // {
  //   img: "assets/images/clients/Reflexion.svg",
  //   title: "Reflexion",
  //   subTitle: "Network Speed Test Tool",
  // },
  // {
  //   img: "assets/images/clients/Musafir.svg",
  //   title: "Musafir",
  //   subTitle: "Network Speed Test Tool",
  // },
  {
    img: "assets/images/clients/Viasat.svg",
    title: "Viasat",
    subTitle: "Network Speed Test Tool",
  },
  {
    img: "assets/images/clients/Ibslogo.svg",
    title: "IBS Software",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Corelogic.svg",
    title: "Core Logic",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Bank.svg",
    title: "Sunflower Bank",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Techouse(1).svg",
    title: "BK Techouse",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Gorillagames.svg",
    title: "Gorilla Games",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Technopro.svg",
    title: "Technopro",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/ADA.svg",
    title: "Aeronautical Development Agency",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Pantrycook.svg",
    title: "Pantry Cook",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Factoreal.svg",
    title: "Factoreal",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Vertaag.svg",
    title: "DLED",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/AttomSolutions.svg",
    title: "ATTOM Data Solutions",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Phfi.svg",
    title: "Public Health Foundation of India",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Stronghome.svg",
    title: "Strong Home Mortgage",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Cbzhold.svg",
    title: "CBZ Holdings",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Securiti.svg",
    title: "Securiti",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Charllotte.svg",
    title: "Charlotte Russe",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Nwu.svg",
    title: "Northwestern University",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Defrnd.svg",
    title: "Defence Research & Development Organisation",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Netset.svg",
    title: "NetSet Communications",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Ucl.svg",
    title: "University College London",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/updintertrust.svg",
    title: "Intertrust",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "assets/images/clients/Intersystems.svg",
    title: "InterSystems",
    subTitle: "Lorem Ipsum",
  },
];

export const caseimgs2 = [
  {
    img: "/assets/images/casestudies/applicationbuild.svg",
    date: "October 2020",
    title: "Application Build & Deployment on AWS",
    service: ["Application Development"],
    industry: ["Finance"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/aws1.svg",
        desc: "Our customer’s point of concern was that, in the beginning, the DEV team used to release the build to QA manually because of which a lot of time was spent on the process of releasing the build. Also the team used to store the DB related credentials in GIT, so those who had access to the environment could easily  access all information which could lead to security issues in the project. Due to all this, clients wanted to migrate their infra to AWS as it was hosted in a non-cloud environment.",
        subTitle1: "Challenge",
        subtitle2: "How did we overcome?",
        img2: "/assets/images/casestudyinfo/aws2.svg",
        subtitle3: "Benefits",
        point: {
          desc1: [
            "The team used to store all the DB related credentials in GIT. We then researched some DevOps tools closely to find the best solution possible and we concluded that storing all credentials in the vault could potentially be the best option. Hence, the DevOps team now had a challenge as to how we could create customer wise secret folders and fetch the vault and overwrite them to specific files.",
          ],
          desc2: [
            "We have added a new release .txt file along with the source code, and with the release of a new source code Dev team will be updating the release.txt file. Jenkins will poll the master branch or any new commits happen with the required branch the code will be checked out to the jenkins node where the application is deployed. With the parameterized build option in jenkins, we defined the environment variable in the parent build and passed it as a string parameter to the downstream builds while sending the consolidated build report. We have configured all the infra and customer DB related credentials in the vault and while build Jenkins jobs, credentials are being fetched from the vault and overwritten to specific files at the time of application boot up. Also, the access to vault UI will be restricted to the DevOps team. We used Terraform script to handle the AWS Infra setup. Then upon the Jenkins trigger, we are building the docker image which later on is pushed to ECR and at the time of deployment we are fetching images from the ECR and deploying the application based on the parameters that are passed from the parent build.",
          ],
          desc3: [
            "The benefits of introducing this process were implementing continuous integration, developers can do frequent code commits which helps to identify bugs and quality related issues quicker and earlier, secure deployments, and decreased full build time. No manual intervention is needed after code has been submitted to a repository. After successful completion of the deployment process the mail will automatically trigger in both the failure and success case. ECR which provides the feature of image scanning helps in identifying software vulnerabilities in container images. One of the other benefits is that since we have introduced a vault to store the entire customer & infra related credentials, it is more secure than ever. The development team has been happy with the automated build process and also easy to handle the application deployments without putting a lot of effort.",
          ],
        },
      },
    ],
  },

  {
    img: "/assets/images/casestudies/machinelearning.svg",
    date: "September 2020",
    title: "Recipe Recommendation Machine Learning Model",
    service: [
      "Application Development",
      "Machine Learning & Artificial Intelligence",
    ],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/machinelearning1.svg",
        desc: "The existing version of our application used to recommend recipes by accepting ingredients from the user and starting an external API call that resulted in recipes with both user preferred and additional ingredients. In this case, results were inaccurate because the number of ingredients matched using a dynamic search. To overcome this drawback, we enabled the machine learning model for recipe recommendation. ",
        subTitle1: "Challenge ",
        subtitle2: "How did we overcome/achieve?",
        img2: "/assets/images/casestudyinfo/machinelearning2.svg",
        subtitle3: "Benefits",
        point: {
          desc1: [
            "The original data available from the application had fewer records and not a lot of recipe sources were available on the internet. We looked for a static data source to even begin to evaluate the models. We started collecting data from various recipe websites that were arid and challenging for instance websites robot blocked our scrapping agent. We performed data cleaning several times and got it reviewed by the stakeholders for validations. Data collected from a few recipe sources were much more in count than the rest of other sources. Finding the best fit algorithm for the data (was) one of the most important tasks.",
          ],
          desc3: [
            "AI based recipe recommendation is accurate, trending and liked by users. Ingredients of resulting recipes make a close match with user ingredients. The user could choose the best rated and top recipes from the results. By using a pre trained model results are much faster than dynamic search.",
          ],
          desc2: [
            "We started with collecting recipe data and associated ingredients. Web scraped a few recipe websites and also used supported API’s to get some more recipe data. We further cleaned and processed the data to arrive at a unique ingredient and recipe list. We then started evaluating several machine learning models and looked at the accuracy of the results that best fit client needs and usage and then trained models and tested the recipe predictions for a set of ingredients. Once the training and testing were complete, we deployed the machine learning model as a REST service, which the mobile application was free to call as many times.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/stockpile.svg",
    date: "October 2020",
    title: "Stockpile Measurement",
    service: ["Embedded & IoT", "Application Development"],
    industry: ["Aerospace & Defence"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/stockpile1.svg",
        desc: "While surveyors and site managers recognize the value of physical on-site inventories of stockpile volumes for accounting purposes, most of them would concede that it is still a time consuming and expensive process, often requiring third-party surveyors to perform the analysis. In addition, there are inherent safety risks with climbing stockpiles.",
        subTitle1: "How did we overcome it?",
        img2: "/assets/images/casestudyinfo/stockpile2.svg",
        subtitle3: "Benefits",
        point: {
          desc1: [
            "Drone Mapping is when you specify the area that you like to map and the drone flies around that area and makes a 3D map out of it, and this technique is called Photogrammetry. Aerial surveying with drones enables surveyors to measure the stockpile with a dense point cloud using Photogrammetry processes.The initial setup of Ground Control Points (or GCPs) is a crucial step to ensure the accuracy and the quality of drone-acquired data. Ground Control Points are points of known geospatial coordinates within an area of interest.",
            "Once the GCPs are positioned and surveyed, the operator ﬂies the drone and maps the entire site automatically by taking multiple pictures. The drone data is uploaded to the WEBODM software for automatic processing, to generate 2D and 3D models. The processed data consists of millions of points allowing 3D reconstruction of the site and enabling volume calculation. The Photogrammetry process matches the geo-referenced points that were surveyed with the GCPs with the overlapping pictures Technologies used:Python, Shell Scripting, JavaScript and Go.",
          ],
          desc3: [
            "Surveys can now be conducted with drones; frequent data collection is proving itself to be cost-effective. It allows better forecasting of the mineral stock available for sale. Eliminating the risks that surveyors working physically on the site face. A fast and inexpensive method of volume measurement is particularly useful to calculate stocks in mines and quarries for inventory or monitoring purposes. With a drone, surveyors can capture many more topographic data points, hence more accurate volume measurements. Since drones are capturing the data from above, operations on site won’t be interrupted.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/detection.svg",
    date: "August 2020",
    title: "Detection & Segregation of Optimal Hands for Visualisation",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/detection1.svg",
        desc: "The client is an aviation organization who are utilizing a Game-based simulation to prepare and train their staff for piloting an aircraft. The client organization is using Simulation-training over typical conventional methods of coaching to increase the efficacy of the training and they’ve accomplished this by integrating Virtual Reality with a Leap-Motion device. However, just one Leap-Motion device was employed in their training and since the range of Leap-motion devices is lower, the field of view in Virtual Reality was circumscribed and the trainee’s hand movements were restricted to a particular distance which resulted in high uncertainty of replicating the simulated experience in the real world. So, to overcome this constraint we were bestowed with the task of integrating Multiple Leap-Motion devices to increase the accuracy and the field of view in the Simulation.",
        subTitle1: "Presented task and solution",
        img2: "/assets/images/casestudyinfo/detection2.svg",
        subtitle3: "Benefits and results",
        point: {
          desc1: [
            "Expand the area of hand detection by employing multiple Leap Motion devices.Manually calibrate the captured hands obtained  by multiple Leap Motion devices to one master device. To  overcome the present limitation in the Client’s game simulation,we implemented a function that determines how many Hands are within the efficacious range of the Leap-Motion devices and captures those Hand data. We also implemented a logic that determines the confidence values of each captured hand predicated on the deviation of each finger. We supplementally developed a function that eliminates the Hand data with lesser hand Confidence values. Thus providing better hand outputs. We have additionally employed a program that grabs hand points from all the devices and relocates them relatively with the Master device depending on the distance and the angle between those devices. Scripting Language Used: C-sharp Tools/Softwares Used: Visual Studio, Unity 3D, Leap Motion Controller",
          ],
          desc3: [
            "Hand detection area: By using multiple Leap motion devices we are increasing the area of hand tracking region for a better AR/VR experience.",
            "Device Synchronization: Minimizing the relative offsets between the hands for smoother and better transitions between devices.",
            "Accuracy: With the implementation of the Confidence function only the best hand is sent to the simulation among all the captured hands, thus providing greater accuracy.",
            "Our implementation provides a more immersive experience for the trainees with little to no risk of uncertainty and disorientation in transferring their simulated experience into the real world. Since the functions are written dynamically, any number of Leap-motion devices can be connected without any changes to the program from the clients themselves. Four Leap Motions were tested with our functions which covered a seated user’s entire reach.",
          ],
        },
      },
    ],
  },

  {
    img: "/assets/images/casestudies/customer.svg",
    date: "November 2020",
    title: "Enhancing Customer Experience",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/customer.svg",
        desc: "To analyze new methods of capturing or receiving honest feedback from customers. To set up a process in place for gauging the health of customer’s satisfaction feedback. To have a strong process and grow the business from our existing customers by improving the customer satisfaction feedback process.",
        subTitle1: "Overview",
        subtitle2: "Issues faced with the existing process",
        img2: "/assets/images/casestudyinfo/customer2.svg",
        subtitle3_half: "Solutions and benefits",

        subtitle3: "Conclusion",
        point: {
          desc1: [
            "Customer satisfaction always remains the key factor in growing the business and will remain so in the future. In order to maintain and obtain continuous business from our existing or new customers, it is very important for the organization to build a strong and long-term relationship through customer compliance. Also, to find the gap and resolve the issues from the client’s end, our organization wanted to improve the process of collecting and analyzing customer satisfaction through genuine feedback.",
          ],
          desc2: [
            "No methods were implemented or introduced in the existing system for analyzing the deviation or non-compliance. Organizations need to measure the increment and decrement in the growth of customer satisfaction Conflict with the openness in satisfaction feedback.",
          ],
          desc3_half: [
            "The quality Control team re-analyzed the existing system and identified the gaps that the organization was facing. We came up with a new process of collecting Customer Satisfaction feedback and measuring the satisfaction data. We upgraded the pattern of questions to be rated or answered by clients.The new Customer Satisfaction feedback form introduces a section of collective questions based on three factors to be rated, between highly dissatisfied to highly satisfied, along with some open-ended questions which give the client the liberty to express their opinions regarding the product or services delivered by Data template.We have shifted the process owners from the project team to a quality team, as we are the ones managing, collecting customer satisfaction now. A quarterly and annual index will be prepared to utilize bar graphs to represent the growth in customer satisfaction.",
            "Easy access for clients to reach out to Data Template’s quality team and raise the issues/conflicts. And provides a clearer way for us to get crystal-clear feedback from clients through direct communication. Enhanced process for examining customer compliance.",
          ],
          desc3: [
            "Once this new process is in place and matured, it will be easy for our organization to resolve the issues from our client’s end and maintain continual improvement. It helps in achieving the business objectives of our clients and provides them a high-quality experience while doing business with us.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/angular.svg",
    date: "November 2020",
    title: "Angular Lazy Loading",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/angular1.svg",
        desc: "The most important part of the development is the performance of the application. Generally, when we develop simple and smaller web applications using the Angular framework, we will help satisfy the customer’s requirements. When we develop the larger application, we need to follow some structures and standards. It may depend on the application type and volume. However, when we develop larger applications, the same standards or structure will impact the performance of the application. We made a lot of new features and pages to the existing application given to us by our clients and the application became vast, which started impacting the performance of the application. The application was taking a lot of time to load for the first time. It was time-consuming and started affecting the performance of the application as well. We had to find an efficient way of making this work and at the same time focus on improving the performance which was of utmost importance to us.",
        subTitle1: "Solution",
        subtitle3_half: "Benefits and results",
        img2: "/assets/images/casestudyinfo/angular2.svg",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Every problem has a solution, and our team came up with a solution. If we want to provide a solution, we need to find the root cause of the issue primarily. After thorough analysis, our team concluded the root cause of the low performance of the application was due to an inapt way of routing implementation.",
            "We then realized that the lazy loading concept of Angular was not implemented and decided to incorporate it in our project. Eventually, we implemented and succeeded. Now the application is smooth and fast and our clients are happy.",
          ],
          desc3_half: [
            "Usually, in development, one huge application will be divided into multiple modules. So here Angular lazy loading helps to load the modules whenever required. This means, if we route to the specific page/component, we will need to load the dependencies. This means there is no need to load all the dependencies at the beginning. We can load the dependencies as and when we need them. Also, the performance won’t be affected as we don’t load the dependencies in one shot.",
            "The lazy loading feature also does the same. This means it helps to load required dependencies when we navigate to the specific page. Hence, it loads faster.",
          ],
          desc3: [
            "Angular with the lazy loading concept, TypeScript, Karma and Jasmin (Unit test)",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/regression.svg",
    date: "August 2020",
    title: "Regression Test Automation for Dataware House Quality Assurance",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/regression1.svg",
        desc: "The term “ETL” stands for Extract Transform Load. The main function of an ETL tool is to extract the data from different sources, transform the data, and load it into a data warehouse. In conventional data quality testing, to test multiple source systems against a single target system we need to extract the data from multiple source systems and save it in a spreadsheet. Then we need to compare this data against target system data.",
        subTitle1: "Challenges",
        subtitle2: "Our solutions",
        img2: "/assets/images/casestudyinfo/regression2.svg",
        subtitle3: "Conclusion",
        point: {
          desc1: [
            "Any software project will have multiple releases hence more effort is taken for regression testing, for each release the regression testing more than the estimated effort to complete the test case execution. Writing the SQL queries and executing them manually using a SQL editor dumps them into excel and compares the data from sources and target data warehouse by viewing them by eye (this is known as ‘sampling’). This process was time consuming, resource intensive and inefficient. This requires SQL programming and very few testers, analysts, or operations people know SQL. Comparing the data from sources and the target data warehouse required more time and effort.",
          ],
          desc2: [
            "Using the QuerySurge (ETL tool), we can automate the manual process of preparing the SQL, executing and comparing the results. QuerySurge is the smart data testing solution for automating the validation & testing of data warehouses and the ETL testing process. Create test queries fast and easily without writing any SQL with query wizards. Compare data from source files and data stores to target data warehouse or big datastore. Compare millions of rows and columns of data in minutes.",
            "Schedule tests to run (1) immediately, (2) any date/time, or (3) automatically after the events ends. Validate up to 100% of all data up to 1000x faster than traditional testing. Increase data validation coverage. Automate your data testing from the kickoff of tests to performing the validation to automated emailing of the results and updating your test management system. Technology used for this is QuerySurge (ETL tool) using QueryWizards",
          ],
          desc3: [
            "Business requirements will continue to change due to business demands, there will be a need to retest the data changes requested by the customers, so regression testing automation is needed to test data warehouse which involves the ETL.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/networkspeed.svg",
    date: "September 2020",
    title: "Network Speed Test Tool",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/networkspeed1.svg",
        desc: "A case study on network speed test tool using selenium python for fetching various network speed parameters while streaming videos and loading websites for desktop and mobile for a leading US based telecommunication company.",
        subTitle1: "Client situation",
        subtitle2: "Our solution",
        img2: "/assets/images/casestudyinfo/networkspeed2.svg",
        subtitle3_half: "How Data Template delivered business values",
        subtitle3: "Various technologies and tools used for automation",
        point: {
          desc1: [
            "Our clients, being the best at what they do, decide to take the initiative to enhance the internet service efficiency to its customers for which there were multiple enhancements made in the application. What’s next? These enhancements needed to be verified. That’s when they turned to us. To develop a tool that can capture various network parameters while streaming a video or a website over the internet. The tool kit was expected to be inputted with the URL and network parameters to be captured and was expected to simulate this either in a laptop or mobile over different platforms like windows, linux, mac, android and IOS. Based on the benchmarks set, the tool kit was supposed to indicate if the expected results are achieved.",
          ],
          desc2: [
            "We defined functions that could return various network parameters using the selenium framework with python as a scripting language. We also created and included various libraries for load websites, streaming videos, for different websites, and fetch details about various speed related parameters for the desktop environments such as instagram, netflix, youtube and twitch.tv.",
            "We also created and included similar libraries for testing mobile network speed using a tool called “Appium” which is similar to selenium and could allow us to simulate a mobile device and run tests on them. Some other libraries were included for initiating skype calls and checking the network stability. The main advantage of these tools is that it not only gives upload and download speeds like many other speed test tools but also gives you other “network parameters” such as streaming videos buffer health, frame drop count, DNS resolution time, website connection and lot more.",
          ],
          desc3_half: [
            "We got the best value for money by using open source technology to develop the tool. We could place network issues and stability of the network under scrutiny. The precision of the tool was on point which indeed helped our client to modify and configure their network as per the accurate network speed parameter results in order to provide a better internet experience to the customers.",
          ],
          desc3: [
            "Scripting languages: Python selenium, Ansible",
            "Tools: Selenium webdriver, Appium, Android SDK,Xcode",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/mpen.svg",
    date: "March 2022",
    title: "mPen",
    service: ["Application Development"],
    industry: ["Healthcare", "Non - Profit Organization"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/mpen1.svg",
        desc: "The mPen is an Electronic Clinical Decision Support Software (DSS), for the use of health care providers, which facilitates evidence-based management of hypertension and diabetes and standardizes care delivery across health facilities.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle3: "Infrastructure and technology used",
        subtitle2: "Problem statement and solution ",
        point: {
          desc1: [
            "Architect, Application Design, Development, and Deployment",
            "Algorithm Integration for Decision System",
            "Sync Application data to the Centralized server",
          ],
          desc3: ["MySQL, Rest, Microsoft WPF, Dot Net and My SQL"],
          desc2: [
            "Development of UI for Data collection",
            "Algorithm Integration for Decision making",
            "Sync Data to the Centralized server",
            "Deployment on user machines",
            "Architect, Application Design, Development, and Deployment",
            "Algorithm Integration for Decision System",
            "Sync Application data to the Centralized server",
          ],
        },

        img2: "/assets/images/casestudyinfo/mpen2.svg",
      },
    ],
  },
  {
    img: "/assets/images/casestudies/mpower.svg",
    date: "March 2022",
    title: "mPower",
    service: ["Application Development"],
    industry: ["Healthcare", "Government"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/mpower1.svg",
        img2: "/assets/images/casestudyinfo/mpower2.svg",
        desc: "The mPower Heart is an Electronic Clinical Decision Support Software (CDSS), for the use of healthcare providers, which facilitates evidence-based management of hypertension and diabetes and standardizes care delivery across health facilities.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technology used",
        point: {
          desc1: [
            "Architect, Application Design",
            "Development and Hosting Support and maintenance of the production environment",
            "Performance Tuning",
          ],
          desc2: [
            "Android App runs on Tabs",
            "Clinical decision Support Screening and management Computing more than 1200 scenarios",
            "Stores patient data in electronic format Aid in counseling patients",
            "Architect, Application Design, Development and Hosting",
            "Support and maintenance of the production environment",
            "Performance Tuning",
          ],
          desc3: [
            "Database MSSQL My SQL, Web Services Azure, VSTS, Red Hat 3 scale API Management, MySQL, Dot net core, Angular, Node JS, PHP, React Native, Microsoft SQL server, Java, my SQL DB, Spring, Hibernate, Apache spark, Mongo DB, R Analytics, Microsoft Azure, Apache Tomcat Server, Windows desktop, Andriod Tablets, SQL lite database, API level 22, Material Design, visual studio 2015, windows presentation foundation, ADO.net Java, Android, SQL lite, Retrofit",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/signable.svg",
    date: "April 2022",
    title: "Signaler's app",
    service: ["Mobility"],
    industry: ["Healthcare"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/signable1.svg",
        desc: "A Communication solution for the deaf-Video Relay Service (VRS). Sign Able is a video + voice mobile app that helps deaf users/hard of hearing communicate with anyone, anytime, anywhere with the help of sign language interpreters-commonly known as Video Relay Service (VRS).",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle3: "Infrastructure and technology used",
        subtitle2: "Problem statement and solution",
        point: {
          desc1: [
            "Architect, Application Design, Development, and Deployment",
            "Sync Application data to the Centralized server",
          ],
          desc3: [
            "Android, Python, Django and Django REST, EC2 server (AWS), web rtc and quickblox Java, Android, SQL lite, Django",
          ],
          desc2: [
            "Deaf people face social isolation, a majority are illiterate and have little access to education and employment. To facilitate communication for the deaf community, Signaler’s app offers deaf people a mobile interpretation service in Indian Sign Language, which can enable them to communicate with anyone, anywhere, anytime. Signable is a video+ voice mobile app that helps deaf users/hard of hearing communicate with anyone, anytime, anywhere with the help of sign language interpreters-commonly known as Video Relay Service (VRS). Two-way calling app so that family, friends, and other essential service providers can call deaf people; Optimize the app’s user interface to improve user experience and video streaming optimization for low-bandwidth settings.",
          ],
        },
        img2: "/assets/images/casestudyinfo/signable2.svg",
      },
    ],
  },
  {
    img: "/assets/images/casestudies/stemi.svg",
    date: "February 2022",
    title: "Web App for Heart Attack treatment",
    service: [],
    industry: ["Healthcare", "Non - Profit Organization"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/stemi1.svg",
        desc: "STEMI Charitable Trust is a not-for-profit organization dedicated to provide indigenous, affordable diagnosis & treatment of STEMI heart attacks.It was developed in collaboration with national & international researchers in internal medicine, emergency medicine & interventional cardiology.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle3: "Infrastructure and technology used",
        subtitle2: "Problem statement and solution",
        point: {
          desc1: [
            "Performance and Data Validation.",
            "Data Visualization and Data application monitoring.",
            "Developing REST APIs.",
          ],
          desc3: ["SQLite, Mysql, MongoDB, AWS, FTP"],
          desc2: [
            "Delay in diagnosis and effective treatment strategy of Heart Attack patients.",
            "Our Application reduces Heart Attack patient rates in the world and Reduces delays in access to hospital care & ensuring provision of affordable treatments.",
          ],
        },
        img2: "/assets/images/casestudyinfo/stemi2.svg",
      },
    ],
  },
  {
    img: "/assets/images/casestudies/grovo.svg",
    date: "November 2022",
    title: "QA Automation for SAAS Learning Platform",
    service: ["Cloud & DevOps", "Software Testing"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/grovo1.svg",
        desc: "Grovo is a New York City based technology company that provides a SaaS learning platform to improve and advance employee and organizational performance. Grovo provides organizations with a software as a service micro learning platform. This platform comes with a wide range of topics along with a dashboard having analytical data on the usage of the content. Grovo caters to learning and development needs of organizations from various industries node using various technologies.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Infrastructure and technology used",
        point: {
          desc1: [
            "Implementation of robust quality processes across the organization. Design and execute tests to ensure that there is seamless integration between various modules of the micro learning platform. Design and implement Test Automation framework to automate the test case execution.",
          ],
          desc2: ["Selenium, Python, Jenkins, Mongo DB"],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/mya.svg",
    date: "September 2022",
    title: "Recruitment Automation project",
    service: ["Service Intergration"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/mya1.svg",
        img2: "/assets/images/casestudyinfo/mya2.svg",
        desc: "Mya Systems provides the leading recruitment automation and conversational AI platform transforming the hiring process by automating outreach and communications across the end to end candidate lifecycle. Mya systems has developed a new generation recruitment tool with AI enabled chat bots that interact with candidates in real time, screen them on skills and job requirement alignment, and schedule an interview if there is a match, keep a track of conversation history and candidate’s application status.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle3: "Infrastructure and technology used",
        point: {
          desc1: [
            "Integrated Six Third party platforms.",
            "Automated test cases integrated with Circle-CI builds.",
            "Coded to ensure data privacy, configuration security, REST API HMAC security and efficient web hook payload validations.",
          ],
          desc3: ["Python, AWS, GIT"],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/ussd.svg",
    date: "October 2022",
    title: "USSD Based Application",
    service: ["Mobility"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/ussd1.svg",
        desc: "CRG application is a game based on USSD technology and provides a platform to play the game even from the basic feature phones with keyboard and without internet. The USSD provides a simple interface to access the application.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Infrastructure and technology used",
        point: {
          desc1: [
            "Architect, Application Design, Development and Hosting",
            "Support and maintenance of the production environment",
            "Performance Tuning",
          ],
          desc2: ["USSD, Node JS, PostgreSQL"],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/ios.svg",
    date: "October 2022",
    title: "IOS & Android",
    service: ["Application Development"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/ios1.svg",
        desc: "CRG application is a game based on USSD technology and provides a platform to play the game even from the basic feature phones with keyboard and without internet. The USSD provides a simple interface to access the application.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Infrastructure and technology used",
        point: {
          desc1: [
            "Develop and maintain IOS and Android based mobile and tablet applications.",
            "Stand alone apps with local data storage capacity.",
            "Develop Apps with server integration.",
            "Development on Native Android, Swift and Cross Platform.",
            "Development on USSD based applications for supporting basic feature phones (Keyboard phones) with any configurations.",
          ],
          desc2: ["Native Android, Swift, Cross platform, USSD, SQLite"],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/thinkhr.svg",
    date: "November 2022",
    title: "Infrastructure & Deployment Automation",
    service: ["Cloud & DevOps"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/thinkhr1.svg",
        img2: "/assets/images/casestudyinfo/thinkhr2.svg",
        desc: "ThinkHR is a US based technology company and ThinkHR combines the best in live advisor expertise with innovative technology and comprehensive and instructional content to help organizations address all potential people risks, including guidance on preparing for and managing those risks.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Infrastructure and technology used",
        subtitle3: "Problem statement and solution",
        point: {
          desc1: [
            "Infrastructure creation as a code (through the terraform on the AWS cloud environment).",
            "Continuous build and deployment through the terraform on AWS ECS Cluster.",
            "EKS cluster creation on AWS.",
          ],
          desc2: [
            "Docker, terraform, AWS, GitHub, Route 53, AWS IAM, AmazonECS, Amazon EKS",
          ],
          desc3: [
            "Infrastructure creation through terraform",
            "Documentation of Infrastructure services",
            "Continuous deployment through the ECS Cluster by using terraform scripts",
            "The solution was infrastructure and deployment automation and better documentation and support.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/factoreal.svg",
    date: "September 2022",
    title: "Introducing DevOps practice & build CI or CD Pipelines",
    service: ["Application Development"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/factoreal1.svg",
        desc: "Factoreal is a marketing automation platform to manage repetitive marketing processes efficiently. This helps enterprises to manage the entire lifecycle of marketing channels effectively across various digital social media platforms like Facebook, Twitter, LinkedIn.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Infrastructure and technology used",
        point: {
          desc1: [
            "Design and development of LinkedIn integration with Factoreal.",
            "Research on the LinkedIn support for integration with external platforms.",
          ],
          desc2: [
            "React, Kafka, PostgreSQL, Python, redis, node JS, Cassandra",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/modeling.svg",
    date: "August 2022",
    title: "Modeling & BI Reporting for Charlotte Russe",
    service: ["Business Intelligence & Big Data Analysis"],
    industry: ["Retail"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/modeling1.svg",
        img2: "/assets/images/casestudyinfo/modeling2.svg",
        desc: "Charlotte Russe is a Clothing Retail Store headquartered in San Diego, California. They Service catering fashion needs of women in their teens and twenties and Operates over 550 stores across 45 states in the US.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle3: "Infrastructure and technology used",
        subtitle2: "Problem statement and solution",
        point: {
          desc1: [
            "Architectural restructuring and migration of the existing Data model and Reporting Platform.",
            "Assist-build analytical dataset and provide ad-hoc analysis to the analytical team.",
            "Provide analytical solution on provided case studies",
          ],
          desc2: [
            "Develop and maintain Data Mart; housing data serving all the reporting needs and requests across organization. Migrate and Phase out the legacy residing platform in Oracle-Sap Hana to SQL Server – Power BI and SSRS platform. Improve existing reporting framework by unifying data across different sources and built in a scalable system for any further enhancements. The solution was Unified Data Repository, Under single Umbrella, Scalable Solution with ad-hoc request services and efficient and cost effective reporting service.",
          ],
          desc3: [
            "Oracle, SQL Server, Hive, SSIS, Scripting, R, Python, PowerBI, SAP Webi, SSRS, ASP.NET",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/flightops.svg",
    date: "June 2023",
    title: "Flight Operation Application Development",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/flightops1.svg",
        img2: "/assets/images/casestudyinfo/flightops2.svg",
        desc: [
          "Designed for airlines, alliances, and airline groups. iFlight Ops optimizes flight operations for increased efficiencies, reduced costs, and improve on-time performance. iFlight provides airline operations control centers with real-time dynamic situational awareness on end-to-end flight operations. iFlight Ops enables proactive decision-making and improves operational efficiencies, On-Time Performance (OTP), reduces fuel costs through optimizing aircraft allocation, and enables all stakeholders to collaboratively decide and execute the best disruption management strategy.",
        ],
        subTitle1: "Challenge",
        subtitle2: "Offered Services",
        subtitle3_half: "Technology Offering",
        subtitle3: "Solution",
        point: {
          desc1: [
            "Gantt Distortions due to browser cache issues",
            "Issue with RTU (Real Time Update)",
            "Slowness/load issues due to multiple users using the application at a time",
            "Unawareness about Message processing formats blocks bulk flight data uploading",
          ],
          desc2: [
            "Avoid operational compliance failures",
            "Improve management of schedule change",
            "Reduce the impact of operational disruptions",
            "Improve aircraft utilization",
            "Improve operations staff efficiency",
          ],
          desc3_half: [
            "Angular JS, JSP, Java(Spring MVC), Spring Data JPA, Oracle SQL and PostgreSQL",
          ],
          desc3: [
            "Concentrate on specific web browser  (For eg: Chrome Incognito mode).",
            "Increase the size of the server.",
            "Remove the unwanted or completed data from the server on regular intervals using automatic job runs.",
            "Provide the user manuals with sample templates to avoid the confusions regarding message processing.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/dataextract.svg",
    date: "June 2023",
    title: "Data Extraction to Integrate Different Airline Applications",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/dataextract1.svg",
        img2: "/assets/images/casestudyinfo/dataextract2.svg",
        desc: "iFlight-data-extractor project is a proxy application that connects the external airline client and iFlight-ops application. It is a pass-through application that call iFlight ops for the required data and returns it to the client.",
        subTitle1: "Challenge",
        subtitle2: "Offered Services",
        subtitle3_half: "Technology Offering",
        subtitle3: "Solution",
        point: {
          desc1: [
            "Payload validation against the JSON schema",
            "For the given JSON schema, we needed to validate the incoming request json before calling the required end points.",
            "Challenge here was the in-built json validator didn’t support the data range and required validation",
          ],
          desc2: ["Rest api development", "Ops module integration"],
          desc3_half: [
            "Angular JS, JSP, Java(Spring MVC), Spring Data JPA, Oracle SQL and Postgre SQL",
          ],
          desc3: [
            "We created a customised handler method argument resolver to validate JSON payloads against a JSON schema.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/programtest.svg",
    date: "June 2023",
    title: "Program Testing for Flight Operations Application",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/programtest1.svg",
        img2: "/assets/images/casestudyinfo/programtest2.svg",
        desc: "JUnit Code Coverage refers to the measurement of how much of the source code of a software application is covered by the test cases written using the JUnit testing framework. It quantifies the extent to which the code is exercised by the tests, providing insights into the effectiveness of the test suite in verifying the functionality and behavior of the code. Code coverage is typically expressed as a percentage and represents the proportion of code that has been executed by the tests. It helps identify areas of the code that have not been adequately tested or not tested at all. The higher the code coverage percentage, the more comprehensive the testing is in terms of exercising different code paths, branches, and conditions.",
        subTitle1: "Challenge",
        subtitle2: "Offered Service",
        subtitle3: "Technology Offering",
        point: {
          desc1: [
            "The Roster Code base provided to us by IBS was a legacy codebase, and hence writing Junits for legacy code was a big challenge because the project modules were not much structured for writing Junits.",
            "There were a lot of existing classes for which we had to write the junits. So, understanding the written class file and its code flow and covering them in junits within the stipulated time lines was a big challenge.",
            "Since IBS has their own internal framework (Sprout), writing junits that conforms to those  frameworks was a challenge.",
          ],
          desc2: [
            "Flight operations : Maintaining the flight schedules, service of flight, message regarding update of the flight etc.",
            "Crew Planning & Optimization : Maintaining the crew activities such as employment, roster calendar etc.",
            "Crew Management : Maintaining the crew leave, duty swap , screen access etc.",
          ],
          desc3: [
            "Angular JS, Java, Maven, JSP, Java(Spring MVC), Spring Data JPA, Sprout framework, Jacoco-maven-plugin, Oracle SQL and Postgre SQL",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/venom.svg",
    date: "July 2022",
    title: "Venom",
    service: [],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/venom1.svg",
        desc: "Venom allows you to virtualize your network, network management technology that lower technology costs and reduces complexity. Venom provides a path to enhance network management, lower capital expenses, energy, and real estate and minimize overall complexity",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Infrastructure and technology used",
        point: {
          desc1: [
            "Creates and monitors virtual networks, which consist of a web-based, network drawing tool, core server engine, KPIs, and other monitoring tools, etc.",
            "At high-level venom has 3 components:",
            "One that runs a multi-tenant application in the cloud.",
            "The second consists of Javascript that runs in the browser, these are used to draw and manage templates.",
            "The third is the Engine Host Manager or EHM. The EHM needs to be installed on each server within your computer cluster.",
          ],
          desc2: [
            "Python, MySQL, MongoDB, Kinetic JS, D 3 js, Open Stack, AWS, Selenium, Ansible",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/tellabs.svg",
    date: "June 2022",
    title: "Tellabs Maintenance & Support",
    service: [],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/tellabs1.svg",
        desc: "Venom allows you to virtualize your network, network management technology that lower technology costs and reduces complexity. Venom provides a path to enhance network management, lower capital expenses, energy, and real estate and minimize overall complexity",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Technical Support",
        point: {
          desc1: [
            "Proactive network Maintenance Support, including software installation.",
            "Network performance auditing",
            "Providing Tellabs maintenance and 24/7 support around the globe",
            "Providing development services for new enhancements",
          ],
          desc2: [
            "Tellabs professional services custom designs the script to automate tedious yet critical tasks that could take your staff hours to complete.",
            "Experts manage the process of upgrading software.",
            "Network design consultations",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/telecom.svg",
    date: "August 2022",
    title: "Telecom Network Communication Platform",
    service: ["Software Testing"],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/telecom1.svg",
        desc: "Viasat Inc is a communications company based in Carlsbad, California offering high-speed satellite broadband services Hybrid Carbon Model involves using both Satellite connection and DSL or LTE.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Technical Offering",
        point: {
          desc1: [
            "Testing of data switch from LLN like DSL to satellite.",
            "Network controller failover validation.",
            "Service availability monitoring service for all the in-house services in the Viasat corporate network.",
          ],
          desc2: [
            "QA process implementation Agile framework, Test automation framework Python, Robot Framework, Ansible scripting, Database MongoDB, DynamoDB",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/webvcpe.svg",
    date: "July 2022",
    title: "Web Application Development vCPE",
    service: ["Application Development"],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/webvpce1.svg",
        desc: "A vCPE Hybrid Carbon model involves using both Satellite and Low latency network connection (DSL/LTE) from UT device. The satellite has a high bandwidth and high latency whereas the DSL/LTE has low bandwidth and high latency Viasat provides its customers with Hybrid high latency and high throughput experience by using different routing We are working with the automation team to help configure and test the overall network as well as help in the development of speed testing and debugging tools for different services in core node using various technologies",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Continuous Integration and deployment.",
            "Automation development",
          ],
          desc2: [
            "Technologies used Ansible, Jenkins, Selenium Python, Go lang Python, and Shell Scripting, Database MongoDB, Python, Jenkins, Selenium, Golang, Ansible, shell scripting",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/carbon.svg",
    date: "May 2022",
    title: "Carbon Android Mobile Application",
    service: ["Mobility"],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/carbon1.svg",
        desc: "Customers can login and check how much data they are utilizing on a monthly/daily basis They can even set their usage limit They can view the flow stats of each device in a graphical format",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Architect, Application Design, Development, and Hosting.",
            "Support and maintenance of the production environment",
            "Performance Tuning",
          ],
          desc2: [
            "Technology Java, Android, Database Shared Preferences, Rest API client Retrofit, Java, Android, Retrofit",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/cloudservices.svg",
    date: "April 2022",
    title: "Cloud Services Development",
    service: ["Cloud & DevOps"],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/cloudservices1.svg",
        img2: "/assets/images/casestudyinfo/cloudservices2.svg",
        desc: "Securiti is dedicated to keeping its cloud platform safe from all types of security issues thereby providing a safe and secure environment to our customers dedicated to keeping its cloud platform safe from all issues thereby providing a safe and secure environment to our customers. Securiti is providing multi-cloud environment services to various clients across and building the infrastructure through terraform in the multiple cloud environment and secure the services",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Managing and creating the services on the various clouds (AWS, GCP, Azure and Oracle)",
            "Build and manage the infrastructure as a code through terraform",
            "It offers Data security of the customer applications",
          ],
          desc3: [
            "Tools Terraform Bitbucket, Docker, Kubernetes, Microsoft visual studio code, Google cloud cli, azure cli, Amazon Web Services (AWS), Google Cloud Platform (GCP), Oracle Cloud Platform (OCP) Azure Cloud",
          ],
          desc2: [
            "Implement the services through terraform in the cloud",
            "Troubleshoot and analyze the issues on the cloud environment",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/arti.svg",
    date: "May 2022",
    title: "ARTI Web Application Development",
    service: ["Application Development"],
    industry: ["Satellite & Telecom"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/arti1.svg",
        img2: "/assets/images/casestudyinfo/arti2.svg",
        desc: "ARTI is a  web application owned by Epiphani. This application will be useful whenever we come across any issue regarding anything, we can raise or post an issue by creating an investigation by using this application. Whoever knows the solution can join the investigation and can give suggestions to the issue in different ways like by text, by using commands, Playbooks, Workflows, etc. The participants in an investigation can also chat with Arti bot and discuss the issue that occurred by using useful commands.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: ["E-commerce.", "Networking companies."],
          desc2: [
            "Architect, Design, Develop and Host web applications on  cloud.",
            "Setup Continuous Integration",
            "Performance Tuning",
          ],
          desc3: [
            "Dynamo, React JS, HTML5, CSS3, AWS, AWS Amplify, AWS Cognito, GraphQL Google Map Services",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/analytics.svg",
    date: "January 2022",
    title: "BLMA Business Intelligence & Analytics",
    service: ["Business Intelligence & Big Data Analysis"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/analytics1.svg",
        img2: "/assets/images/casestudyinfo/analytics2.svg",
        desc: "BLMA is a product owned by Atarwa. It is a web application that can be used by different types of manufacturing business entities such as medical, food, etc. The application will be useful to capture the data for the manufacturing process and the same will be used for analytics (graphs & charts). This will help the business entity to improve these manufacturing processes and the quality of the product.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Application Design, Development, and Deployment",
            "Sync Application data to the Centralized server",
            "Analytics monitoring and Report generation",
          ],
          desc3: [
            "PostgreSQL, Python, Angular8, Rest, Angular 8 With Bootstrap 4, PostgreSQL, Java, Python, Angular8",
          ],
          desc2: [
            "Development of UI for data collection.",
            "Capture data for the manufacturing process.",
            "Display the data for the manufacturing process for each item in the form of charts. The summary view of the item will be displayed as box plots and individual graphs will be displayed as Control charts with different types of charts like individual charts, Moving range, and phase change charts.",
            "Provide the option to export Analytics graphs as reports in word/doc format.",
            "Provide a download option for Users to download created reports.",
            "Custom Reports for Users to Monitor the data",
            "Will help to improve the Quality and manufacturing process based on the Analytics",
            "Efficient and cost-effective Reporting Service",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/devops.svg",
    date: "January 2022",
    title: "BLMA DevOps Engagement",
    service: ["Cloud & DevOps"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/devops2.svg",
        img2: "/assets/images/casestudyinfo/devops1.svg",
        desc: "BLMA is a product owned by Atarwa. It is a web application that can be used by different types of manufacturing business entities such as medical, food, etc. The application will be useful to capture the data for the manufacturing process and the same will be used for analytics (graphs & charts). This will help the business entity to improve these manufacturing processes and the quality of the product.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Implementation for the Infrastructure services through the terraform scripts on the AWS cloud environment.",
            "Set up Continuous Integration and Continuous Deployment for the development/production environment.",
          ],
          desc3: [
            "Amazon AWS, Terraform, Application Load Balancers, Autoscaling, Amazon RDS, S3, Amazon ECR, Amazon Certificate Manager , AWS IAM, Jenkins, Docker, GitLab, Tomcat, Hashicorp Vault",
          ],
          desc2: [
            "Provide the AWS Infrastructure Support",
            "Documentation of Infrastructure services and Build and Deployment process",
            "Triggering and Monitoring the builds on the Jenkins",
            "Automation of Infrastructure creation and deployment  process",
            "Better Documentation and Support",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/blmaweb.svg",
    date: "January 2022",
    title: "BLMA Web Application Development",
    service: ["Application Development"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/blmaweb1.svg",
        img2: "/assets/images/casestudyinfo/blmaweb2.svg",
        desc: "BLMA is a web application that can be used by different types of manufacturing business entities such as medical, food, etc. The application will be useful to capture the data for the manufacturing process and the same will be used for analytics (graphs & charts). This will help the business entity to improve these manufacturing processes and the quality of the product",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Application Design, Development, and Deployment",
            "Sync Application data to the Centralized server",
          ],
          desc3: ["PgAdmin, Rest, Angular 8 with Bootstrap 4"],
          desc2: [
            "Development of UI for Data collection",
            "Capture the data for the manufacturing process",
            "Analytics monitoring",
            "Captures of analytics in report generation",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/pantrycook.svg",
    date: "February 2022",
    title: "Pantry Cook Mobile Application Development",
    service: ["Mobility"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/pantrycook1.svg",
        img2: "/assets/images/casestudyinfo/pantrycook2.svg",
        desc: "Pantry Cook is a digital pantry that manages your household ingredients and connects you to major recipe sites to show you recipes to make with what you have. Pantry Cook takes the guesswork out of what to do with all of the partial dry goods or spices in your pantry. With Pantry Cook you can easily organize ingredients into categories to streamline your recipe searches. Recipes returned will include the ingredients you’ve added for the search, but they may also have additional ingredients required.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Application Design, Development, and verification",
            "Integration of third-party service which helps in search food recipes",
          ],
          desc3: [
            "React Js, React Native, PHP, Laravel, MySQL, React, Rest, Ubuntu Server, Bootstrap, React Native, HTML, CSS, MySQL, JQuery, PHP",
          ],
          desc2: [
            "Design, Development of UI.",
            "Collecting data from third-party API.",
            "Fetching data from different websites",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/myroomie.svg",
    date: "December 2021",
    title: "Mobile Application Development",
    service: ["Mobility"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/myroomie1.svg",
        img2: "/assets/images/casestudyinfo/myroomie2.svg",
        desc: "My Roomie is the first student housing-focused company in India to address a massive unmet demand for high-quality accommodation unlike pg or hostel with all the amenities of today. The purpose of undertaking this project is to create an IOS app for MyRoomie Group to track Residents and their activities.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Managing the Residents of the hostel.",
            "It offers the online payment for the residents.",
            "It offers reporting issues faced by residents",
          ],
          desc3: [
            "Development Environment – XCode and VSCode, Production Environment – TestFlight and Apple Appstore, React Native, Swift, and IOS",
          ],
          desc2: ["Leave Request.", "Service Request.", "Payment Request"],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/neota.svg",
    date: "November 2021",
    title: "Neota App for Events",
    service: ["Mobility"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/neota1.svg",
        img2: "/assets/images/casestudyinfo/neota2.svg",
        desc: "Product for Event creation, invitation etc Managing friends ",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Architect, Design, Develop and testing",
            "Performance fine tuning",
            "Publishing on Google play store and Apple App store",
          ],
          desc3: ["IOS - Swift, Android – Native Android, SQLite"],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/voyawebapp.svg",
    date: "October 2021",
    title: "Travel WebApp Development",
    service: ["Application Development", "Mobility"],
    industry: ["Hospitality & Leisure"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/voya1.svg",
        img2: "/assets/images/casestudyinfo/voya2.svg",
        desc: "Voya provides users, their own space to design and create their travel portfolio with all the details they want to. Voya will preserve all travels in one application and allow users to create, store, share their trip information, pictures/videos/audio, itinerary, travel story with their connects.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Architect, Application Design, Development and Hosting",
            "Continuous Integration for Build and Release",
            "Integration with Social Media",
          ],
          desc3: [
            "SQL Server, HTML5, CSS, JQuery, Rest, Facebook, RSK Image Cropper, SDK, Fabric, Firebase, Crashlytics, Google Map Services",
          ],
          desc2: [
            "Architect, Design, Develop and Host web application on  cloud.",
            "Setup Continuous Integration",
            "Performance Tuning",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/manufacturing.svg",
    date: "November 2021",
    title: "Web Application Development",
    service: ["Application Development"],
    industry: ["Manufacturing"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/manufacturing1.svg",
        img2: "/assets/images/casestudyinfo/manufacturing2.svg",
        desc: "Godrej is a web application owned by Vendor Globe. This application is used for the bidding process for material vendor Application is used for end-to-end bidding processes like selecting the vendor.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Architect, Design, Develop and Host web applications on Linux",
            "Setup Continuous Integration",
            "Performance Tuning",
          ],
          desc3: [
            "Database MySQL, UI HTML 5 CSS 3 JavaScript, Backend CodeIgniter (PHP), CodeIgniter, MySQL, javascript, and HTML5",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/handtracking.svg",
    date: "December 2021",
    title: "Hand Tracking Algorithm",
    service: ["Embedded & IoT", "Augmented Reality & Virtual Reality"],
    industry: ["Aerospace & Defence"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/handtracking1.svg",
        img2: "/assets/images/casestudyinfo/handtracking2.svg",
        desc: "The Leap Motion Controller is an optical hand - tracking module that captures the movements of your hands with unparalleled accuracy. It can be used in conjunction with Virtual (VR) and Augmented reality (AR) to allow you to grab things without the use of a controller thus creating a more immersive experience as you can just use your hands as you usually would interact with objects.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Algorithm for sending one set of Hand data.",
            "Increasing the Field of View.",
          ],
          desc3: ["Unity, C# (C-Sharp)"],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/education.svg",
    date: "September 2021",
    title: "Whiteboard.Chat",
    service: ["Application Development"],
    industry: ["Education"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/education1.svg",
        img2: "/assets/images/casestudyinfo/education2.svg",
        desc: "Whiteboard.chat is a free interactive online whiteboard for teaching and team collaboration, created by Epiphani Inc, a San Jose, CA-based company. Whiteboard.chat is a virtual whiteboard for teachers, students, remote colleagues, parents, and kids for collaborative learning. It is free to use, connects up to 100 people to a board simultaneously, and exports boards to PDF. It also enables you to easily observe and teach many user boards at the same time.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Content creation by groups of Educators.",
            "Interactive whiteboard with Graphic Tablets.",
            "Contributing to the whiteboard.chat community.",
            "An amazing Online whiteboard for teaching.",
            "Interactive collaborative whiteboards can help with online learning.",
          ],
          desc3: [
            "Technologies used for this Project – React JS, AWS tech stack - like AWS amplify, Cognito, lambda,Amazon S3. ",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/propertydata.svg",
    date: "August 2021",
    title: "Property Data Application Program Interface",
    service: ["Software Testing"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/property1.svg",
        img2: "/assets/images/casestudyinfo/property2.svg",
        desc: "ATTOM’s Property Data API (application program interface) is a self serving platform that powers various industries with premium property data. Their Property API platform consolidates property centric tax, deed, mortgage, and foreclosure data with enhanced neighborhood, school, crime, and community data.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Developing Rest API’s",
            "Continuous integration using Azure Pipelines.",
            "Performance and Data validation.",
            "Data visualization and application Monitoring",
          ],
          desc3: [
            "Database MSSQL My SQL, Web Services Azure, VSTS, Red Hat 3 scale API Management, MySQL, Dot net core, Angular, Node JS, PHP, React Native, Microsoft SQL server",
          ],
          desc2: [
            "API Management.",
            "Our Property API platform consolidates property centric tax, deed, mortgage, and foreclosure data with enhanced neighborhood, school, crime, and community data",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/azure.svg",
    date: "August 2021",
    title: "Azure Serverless Architecture",
    service: ["Business Intelligence & Big Data Analysis"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/azure1.svg",
        img2: "/assets/images/casestudyinfo/azure2.svg",
        desc: "ClosingCorp is the leading provider of residential real estate closing cost data and technology for the mortgage and real estate services industries. ClosingCorp optimizes closing processes and services for mortgage lenders, title and settlement companies and real estate professionals.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Building a centralized Data Warehouse to analyze the business activity.",
            "Migrate existing legacy databases with the newly created Azure SQL database.",
          ],
          desc3: [
            "DEV, PROD, SQL Server 2017, Azure Cloud, SSIS, SSRS, PoweBI, SQL, Java script, sales force, Power BI",
          ],
          desc2: [
            "ClosingCorp Data migration project is to obtain data from the existing systems, mapping this to the new system by applying the new data requirements of ClosingCorp. The data mapping (or the lack thereof) will highlight the transformations required as well as the cleansing activities required to ensure the new system is populated with transformed latest data.",
            "The solution was centralized data warehouse and focused data  arts, Scalable Solution with adhoc request services and efficient and Cost Effective Reporting Service",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/shorenstein.svg",
    date: "September 2021",
    title: "Data Migration & Reporting for Real Estate Organizations",
    service: ["Business Intelligence & Big Data Analysis"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/shorenstein1.svg",
        img2: "/assets/images/casestudyinfo/shorenstein2.svg",
        desc: "Shorenstein is one of the country’s oldest and most respected real estate organizations active nationally in the ownership and operation of high quality office properties. They are a fully integrated real estate investment company, bringing entrepreneurial vision to office and mixed use properties in dynamic markets across the United States.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Migrate Data from Multiple Legacy Systems to the Yardi Voyager Application",
            "Implement new modify existing reports to support day to day activities of the organization.",
          ],
          desc3: [
            "IBM Mainframe,XLS file on Shared Drive, SQL Server Database and SSIS, SSRS Reports, Microsoft SQL server, MongoDB, SSRS, ASP.NET",
          ],
          desc2: [
            "Organization decide to implement Yardi Voyager application to meet their day-to-day operational and finance activities. Data from Legacy systems had to be migrated to the new system. Migrate Data from Legacy systems to Yardi Voyager Platform. Build new reports to support day to day activities.",
            "The solution was Legacy Data available on the centralized system for day today activities of the organization. Custom Reports for the Top Management. Cost Reduction in Maintenance of Legacy Applications.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/lres.svg",
    date: "June 2021",
    title: "QA Process Implement & Automate for Real estate",
    service: ["Application Development", "Software Testing"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/lres1.svg",
        img2: "/assets/images/casestudyinfo/lres2.svg",
        desc: "LRES Corporation is a national Appraisal Management Company, offering a full suite of Residential and Commercial Real Estate Services. They manage a nationwide network of providers, appraisers, agents, brokers, and inspectors to deliver valuations, REO asset management, and HOA solutions to the mortgage and real estate industry.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Implementation of robust quality processes across the organization.",
            "Design and execute tests to ensure that quality of the deliverables are at its best.",
            "Design and implement Test Automation framework to automate the test case execution on the cloud.",
          ],
          desc3: [
            "DEV, UAT, PROD, Azure Cloud, .NET Core 3.1, .NET framework 4.7.1, SQL Server 2017, Katalon, Microsoft SQL Server, Microsoft Azure",
          ],
          desc2: [
            "Associates would obtain the due orders and send the email reminder manually Order, client, vendor, Product, and Vendor Firm dashboards was not loading quickly Manual VENDOR licenses check.",
            "The solution was fetching data of Order, client, vendor, Product, and Vendor Firm dashboards optimized so that all the dashboards are loading faster. Enhanced Automated assignment hours are based on the subject project location. Proprietary, Web Based Order Management System. Out of the Box and Custom Integrations.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/calque.svg",
    date: "July 2021",
    title: "Property & Mortgage Web App",
    service: ["Application Development"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/calque1.svg",
        img2: "/assets/images/casestudyinfo/calque2.svg",
        desc: "Calque is a web application used for managing borrower information, property information and mortgage information it will provide guarantee for a particular period in which the customers current home can be sold, there are two types of user roles i.e. Data Entry person and underwriter who use the application of filling the application form and for approving/rejecting the same after review.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Development and testing the end to end functionality of the admin and customer facing web application.",
          ],
          desc3: ["AWS, ReactJS, Python, SQL"],
          desc2: [
            "The process of buying and selling a home isn’t optimized for consumers, they must time the process just right to sell before they buy or incur additional costs, making this process unnecessarily time consuming, stressful and expensive.",
            "The solution was the ability to manage and handle customer applications for providing a guarantee. Handled complex calculations logic for proving a rule based matrix for choosing a certain offer duration based on the real estate market conditions based on statistical data.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/sunflowerbank.svg",
    date: "June 2021",
    title: "Data Optimization & Analytics",
    service: ["Business Intelligence & Big Data Analysis", "Software Testing"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/sunflowerbank1.svg",
        img2: "/assets/images/casestudyinfo/sunflowerbank2.svg",
        desc: "Sunflower Bank is a US-based bank who provides different loans such as personal, small business,commercial, wealth management, credit cards, homeloans, and more.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Data Template to support the client’s existing engineer and business team for the set of queries and stored procedures to optimize the data and process.",
          ],
          desc3: ["Visual Studio, Azure DevOps, Git, Microsoft SQL Server"],
          desc2: [
            "Clients already have a set of queries and stored procedures and are looking for one developer and one QA resource from Data Template to support their existing engineer and business team. The scope majorly covers the fine tuning, enhancing, checking code back to repository & execution. Data template resources will also assist in building CSV extracts to pass along to Sunflower’s business team for additional analysis.",
            "Data template supports Onsite team for optimizing the existing query and store procedures for the excel report generation and SSIS package work. ",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/bktechouse.svg",
    date: "October 2022",
    title: "Deployment Automation & DevOps Engagement",
    service: ["Cloud & DevOps"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/bktechouse1.svg",
        img2: "/assets/images/casestudyinfo/bktechouse2.svg",
        desc: "BK Techouse is a Technology Company dedicated to delighting their customers, employees and shareholders by providing High Quality Innovative Technology products and services that empower their customers to strive in a fast changing market.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Introducing DevOps practices to BK Techouse Building CI/CD pipeline for their existing applications. Training BK Techouse Tech team on Best practices.",
          ],
          desc3: ["Docker, GitLab, NGINX, PostrgeSQL"],
          desc2: [
            "Deployment automation.",
            "Incorporation of DevOps Best practices.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/intertrust.svg",
    date: "May 2021",
    title: "Investor Portal",
    service: "",
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/intertrust1.svg",
        img2: "/assets/images/casestudyinfo/intertrust2.svg",
        desc: "Intertrust is a publicly traded international trust and corporate management company based in Amsterdam, Netherland known for its fiduciary services, which includes tax, trust, business management.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Code migration.",
            "Convert stored procedure to cube js.",
            "Training Widget data source and few features using cube js.",
          ],
          desc3: ["Dot NET core, React JS, Azure SQL"],
          desc2: [
            "Security, contact & Fund Manager as part of the newsletter select the file and distribute to customers (Investor, contact & fund Manager) as part of the newsletter feature. RBAC Integration for reporting.Data Migration.",
            "Existing web reporting missing the contact person role instead to be created as an investor.",
            "The same should be resolved in the new approach. Move existing data from POV and Web Reporting database into Azure SQL database.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/firstamerican.svg",
    date: "May 2021",
    title: "AWS Serverless Architecture",
    service: ["Business Intelligence & Big Data Analysis"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/firstamerican1.svg",
        img2: "/assets/images/casestudyinfo/firstamerican2.svg",
        desc: "First American Corporation. First American Financial Corporation is a United States financial  services company and is a leading provider of title insurance and settlement services to the real estate and mortgage industries.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Building Inhouse Data Warehouse to remove dependency from external third part services.",
            "Integrate Existing systems with the newly developed API’s to integrate with DataWarehouse.",
          ],
          desc3: ["DynamoDB, MongoDB, NodeJS, AWS API Gateway"],
          desc2: [
            "First American Mortgage team was relying on Fraud Guard to get the Property Information.",
            "This was costing  the team considerable amount of money annually.",
            "New System was proposed to Extract and store data from  XML and build data store.",
            "Extraction of data from XML files using Node.JS and storing in DynamoDB",
            "Building Node API’s to integrate with DataWarehouse.",
            "Unified Data Repository Under single Umbrella",
            "Scalable Solution  with ad-hoc  request services",
            "Efficient and Cost Effective Reporting  Service",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/leadsherpa.svg",
    date: "April 2021",
    title: "SMS & Postal Card Campaigns",
    service: ["Application Development"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/leadsherpa1.svg",
        img2: "/assets/images/casestudyinfo/leadsherpa2.svg",
        desc: "Lead Sherpa™ is a company which has been solving Real Estate marketing challenges since 2018. It all started when Lead Sherpa created the first TCPA compliant text messaging service now there are multiple features like building a suite of lead generation, lead sourcing and  campaign tracking tools such as sms and sending postal cards.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "SkipTrace",
            "SMS Campaigns",
            "Postal Card Campaigns",
            "Advanced Search options",
          ],
          desc3: [
            "Python Django Rest framework , Celery, React Js, Cypress tool for automation, Linode, circleci, docker compose & sentry",
          ],
          desc2: [
            "Worked on replacing the existing subscription mechanism using  braintree using new payment gateway paddle.",
            "Added More enhancements to the existing like Direct Mail campaigns, More filter options for search and also stabilizing the system by fixing the bugs.",
            "Added more test coverage to the system and automated the features  of existing application using cypress tool.",
            "Come up with the deployment process for Front end.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/springboardauto.svg",
    date: "March 2021",
    title: "SpringboardAuto Service Integration",
    service: ["Application Development"],
    industry: ["Finance, Mortgage & Real Estate"],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/springboardauto1.svg",
        img2: "/assets/images/casestudyinfo/springboardauto2.svg",
        desc: "SpringboardAuto is a direct-to-consumer auto loan platform transforming car buying and  financing into a modern online and mobile friendly experience.Consumers enjoy a self-serve, transparent and easy-to-use online transaction that puts them in control of their financing.",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Architect solution for Service development",
            "Integration of Third party service which helps in Automated workflow of Loan approval process.",
            "Windows Service Development for Automate Mail Triggering.",
          ],
          desc3: [
            "SQL Server, .NET Web services, Node JS, Meridian Link (loan Origination System), Equifax, CarzInspection, Kelly, Blue Book, Fedex, Skye Closing, Twilio, Docusign, Mail Chimp",
          ],
          desc2: [
            "Architect, Design, Develop and Host web application on  cloud",
            "Setup Continuous Integration",
            "Performance Tuning",
          ],
        },
      },
    ],
  },

  {
    img: "/assets/images/casestudies/documentintelligence.svg",
    date: "July 2024",
    title: "Document Intelligence",
    service: ["Application Development", "Mobility"],
    industry: "Finance, Mortgage & Real Estate",
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/documentintelligence1.svg",
        // img2: "/assets/images/casestudyinfo/realtycare2.svg",
        desc: "Data Template was engaged to provide solution by developing an AI-powered tool to automate the extraction of insurance policy data from PDFs into a structured JSON format. The tool extracts key details, compares them with Excel data for accuracy, and performs financial analysis using Databricks. It then generates PPTs to help brokers assess whether switching insurance carriers is financially beneficial",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Architect, Application Design, Development",
            "Support and maintenance of the production environment",
            "Performance Tuning",
          ],
          desc2: [
            "Develop an automated solution for extracting and analyzing detailed insurance policy data from PDF documents. The objective was to consolidate this data into a structured format and perform a financial comparison to assess the advisability of switching insurance carriers from a business perspective. Developed an AI-powered tool that automates the extraction of required data fields from insurance policy PDFs into JSON format. This involves creating prompts to accurately extract information like Insured Name, Date of Birth, Policy Number, etc. The extracted data will be compared with manually stored data in Excel to ensure accuracy. Utilizing Databricks, we ll process and analyse the validated data, performing financial calculations such as surrender values, premiums, payouts, and assessing tax implications.Finally, we'll generate presentations (PPTs) to visualize the analytics, assisting brokers in making informed recommendations about whether to surrender existing policies and adopt new ones.",
          ],

          desc3: [
            "GPT-4o-mini (OpenAI):, Python, Excel ,JSON ,Analytical Tools (e.g., Databricks)",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/insureplat.svg",
    date: "July 2024",
    title: "InsurePlat",
    service: ["Application Development", "Mobility"],
    industry: "Finance, Mortgage & Real Estate",
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/insureplat1.svg",
        // img2: "/assets/images/casestudyinfo/realtycare2.svg",
        desc: "Data Template was engaged to provide solution by developing a conversational chatbot using the Rasa Pro framework to assist prospects with Medicare benefits, eligibility checks, and enrolment. The chatbot processes queries and connects users to Medicare sales agents for accurate and timely information. ",
        subTitle1: "Data Template’s Engagement with Client",
        subtitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Architect, Application Design, Development",
            "Support and maintenance of the production environment",
            "Performance Tuning",
          ],
          desc2: [
            "Identified the need for a system that can facilitate conversations between prospects and Medicare sales agents. The system should help prospects understand Medicare benefits, check eligibility, and enroll in Medicare plans.",
            "Developed and deployed a conversational chatbot using the Rasa Pro framework and chat widgets. This chatbot processes user queries, connects prospects with Medicare sales agents, and provides accurate and timely information regarding Medicare benefits and enrollment ",
          ],

          desc3: [
            "Open AI GPT-4, Rasa Pro CALM Framework, ReactJS, Python, Flask, VS Code.",
          ],
        },
      },
    ],
  },
  // {
  //   img: "/assets/images/casestudies/realtycare.svg",
  //   date: "April 2021",
  //   title: "AgentPerformance.ai",
  //   service: ["Application Development", "Mobility"],
  //   industry: "Finance, Mortgage & Real Estate",
  //   drilldown: [
  //     {
  //       img1: "/assets/images/casestudyinfo/realtycare1.svg",
  //       img2: "/assets/images/casestudyinfo/realtycare2.svg",
  //       desc: "Data Template was engaged to provide solution by developing a training app that generates virtual customer leads based on user-defined prompts such as - name, birthdate, Medicare plan details. Agents interact with ChatGPT in realistic conversations, using Deepgram for speech. The app analyzes the conversation to evaluate performance and provide feedback, enhancing agent readiness and communication.",
  //       subTitle1: "Data Template’s Engagement with Client",
  //       subtitle2: "Problem statement and solution",
  //       subtitle3: "Solution",
  //       subtitle4: "Infrastructure and technologies used",
  //       point: {
  //         desc1: [
  //           "Architect, Application Design, Development",
  //           "Support and maintenance of the production environment",
  //           "Performance Tuning",
  //         ],
  //         desc2: [
  //           "The traditional training methods for insurance agents are often time-consuming and lack real-world scenarios that agents will face in customer interactions. This can result in agents being unprepared for actual enrollment conversations, leading to inconsistent communication and suboptimal outcomes for customers. Additionally, assessing agent performance and providing constructive feedback in a meaningful way remains a challenge, which can hinder their development and effectiveness.",
  //         ],
  //         desc3: [
  //           "Our solution involves developing a transaction application designed to streamline the training process for insurance agents handling Medicare enrollment. Initially, the application will generate a list of potential virtual customers (leads) based on user-defined prompts such as first name, last name, date of birth, and Medicare plan details. After the virtual customers are created, the agents will engage in simulated conversations with ChatGPT, who will act as the customer. Using Deep gram, agents can converse naturally, either speaking or reading responses. Once the interaction concludes, the application will analyze the conversations to evaluate agent performance and provide feedback based on predefined goals and criteria.",
  //         ],
  //         desc4: ["OpenAI GPT-4 Model, Python, Deep gram"],
  //       },
  //     },
  //   ],
  // },
  {
    img: "/assets/images/casestudies/zondaipad.svg",
    date: "March 2021",
    title: "Zonda Ipad Salesforce Application Development",
    service: ["Application Development"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/zonda1.svg",
        img2: "/assets/images/casestudyinfo/zonda2.svg",
        desc: "Zonda offers an approachable and intuitive way to access real-time data across the United States With detailed maps, comprehensive data, and interpretive, narrative, and deliver all the key housing market information in a user-friendly format on the go Search, save, and monitor hot spots ripe for opportunity Uncover nearby project trends such as monthly absorption rates, inventory, and remaining lots available as well as demographics important to homebuyers such as schools, crime rates, and the job market conditions.",
        subTitle1: "Data Template’s Engagement with Client",
        subTitle2: "Problem statement and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "Architect, Application Design, Development, and Hosting",
            "Support and maintenance of the production environment",
            "Performance Tuning",
          ],
          desc2: [
            "Architect, Design, Develop and Host applications on cloud",
            "Setup Continuous Integration",
            "Performance Tuning",
          ],
          desc3: [
            "UI Visual Force, HTML 5 CSS, JavaScript, Platform Salesforce com, Apex, Triggers, Business automation using Process, builders and Workflows, Web Services Rest, Integration Google Map Services, Deployment Environment Salesforce com",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/assetmanagement.svg",
    date: "February 2021",
    title: "Internet of Earthmoving Equipment “Asset Management”",
    service: ["Embedded & IoT"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/assetmanagement1.svg",
        img2: "/assets/images/casestudyinfo/assetmanagement2.svg",
        desc: "Increase in heavy equipment rentals business across the globe Remote location, operating conditions, and renting business models started creating new challenges for OEM. In order to address these challenges while enabling rental agencies to be in constant touch with equipment, and thus empowering business in many ways, OEM decided to adopt the latest “Smart and Connected” technology.",
        subTitle1: "Solution",
        subtitle3: "Benefits",
        point: {
          desc1: [
            "Data Template worked with client for the design and development of hardware and software platforms for connected solutions as per OEM’s requirements.",
            "Design development of sensor interface and data acquisition system.",
            "Cloud enablement using advanced satellite and cellular technologies",
            "ECU HW SW system verification and validation",
            "ECU sends out real time data en abling user to stay informed all the time User can access this data through SMS, email or by web link.",
            "ECU collects important information about machines such as its health, location and numerous other vital data through sensors.",
            "This data is then transmitted to a secure server and from there it is sent out to the user. User can then use this data to make critical decisions about your business or the machine.",
          ],
          desc3: [
            "By monitoring machine hours accurately, the system ensures maintenance is kept up to date.",
            "Alerts keep you aware of the machines needing routine maintenance.",
            "Critical machine alerts, equipment tracking.",
            "Allows to demonstrate the service history of any machine which is vital for good resale.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/internetofuv.svg",
    date: "January 2021",
    title: "Internet of UV Curing System “Predictive Maintenance”",
    service: ["Embedded & IoT"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/internetofuv1.svg",
        img2: "/assets/images/casestudyinfo/internetofuv2.svg",
        desc: "In mass production lines it's very essential to predict the condition of in service equipment. This predictive maintenance approach promises cost savings over routine or time based preventive maintenance. To address this, the client decided to engage with experts that would articulate the end to end solution in a contextual manner and help them to develop “Advance Information Monitoring System”.",
        subTitle1: "Data Acquisition",
        subtitle3: "Application Development and Cloud Deployment",
        point: {
          desc1: [
            "Sensor interface development.",
            "Self monitoring firmware development",
            "Proprietary protocol design and implementing over industrial bus",
          ],
          desc3: [
            "Web socket development,Integrating Analysis algorithm (customer IP) PC, web, mobile application development, User profiling and screen development.",
            "Seamless flow of data between local network, back office and smart phone, Diagnostic, information available with Technician on smart phone, Remote firmware upgrade and updates.",
          ],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/dled.svg",
    date: "February 2021",
    title: "DLED Web Application Development",
    service: ["Embedded & IoT"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/dled1.svg",
        img2: "/assets/images/casestudyinfo/dled2.svg",
        desc: "The purpose of undertaking this project is to fetch real time data from the sensors and show all the parameters like temperature, co 2 moisture, electrical conductivity and displaying the same in the application’s GUI.",
        subTitle1: "Data Template’s engagement with client",
        subtitle2: "Presented task and solution",
        subtitle3: "Infrastructure and technologies used",
        point: {
          desc1: [
            "The user will be able to grow their crops inside a controlled environment.",
            "The application will show all the real time parameters along with the crop watering cycle which are essential for the better growth of plants.",
            "The user should be able to control all the parameters remotely for the better growth of the crop.",
          ],
          desc2: [
            "The crops are grown inside an incubator where the sensors are attached",
            "These sensors along with the light engine is connected to Arduino board. Arduino board is connected to Raspberry Pi 4 which has WIFI module to transfer the real time data from sensors to the application.",
            "The user is created by the admin and user gets the credentials in their mail",
            "The user can login with their credentials and monitor all the parameters remotely through the application. The user can operate in Auto mode or manual mode. In Auto mode the user sets a range for all the parameters and the same are maintained in the incubator. In Manual mode the user can change the value of each parameter manually by sliding atmospheric sliders and light level sliders provided in the application.",
            "The Raspberry Pi will be sending requests to the server with all the values of real time sensor data and the server will be comparing these to the values set by the user and sending the appropriate response to Raspberry Pi.",
            "The Raspberry Pi sends instructions to the Arduino board to change the values of the parameters when the values do not meet the permissible range. The user can also view the graph of each parameter variance over growth period.",
          ],
          desc3: ["Python, React, postgresql"],
        },
      },
    ],
  },
  {
    img: "/assets/images/casestudies/nft.svg",
    date: "January 2021",
    title: "Development of NFT Marketplace",
    service: ["NFT & Blockchain"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/nft1.svg",
        img2: "/assets/images/casestudyinfo/nft2.svg",
        desc: "NFT marketplace, as the name implies, is a decentralized platform where users can create, buy, sell, and store non-fungible tokens. NFT marketplace facilities NFT minting and trading at a global scale, while blockchain as its underlying technology ensures transparency and immutable recording of the digital asset tokenization and trading process.",
        subTitle1: "The following features can be built to the NFT marketplace",
        subtitle3: "Infrastructure and technology used",
        point: {
          desc1: [
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Sign Up/Login
            </span>,
            "To buy or sell on the platform, a user should be able to create an account. The sign-up process can be easily accomplished through phone numbers, emails, or social media accounts. After that,the users are asked to state their cryptocurrency account for transactions. However, if they do not have one, they may sign up through Coinbase wallet, Metamask, or other crypto wallets.",
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Storefront
            </span>,
            "NFT marketplace should have a storefront that offers users all the information required for an item: bids, owners, previews, trading overview, or price history.",
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Filters
            </span>,
            "Using filters, it becomes easier to navigate a site, specifically if you plan to build a marketplace place for a lot of collectibles. By adding the filters feature, users can select items by payment method, listing status, category, and collection.",
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Searching for items
            </span>,
            "An NFT marketplace platform should support tagging and category management to allow users to search collectibles. Use a search bar on the site and add categories.",
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Create listings
            </span>,
            "A user should be able to create and submit collectibles. Using this feature, a user should upload files and fill in the token information such as name, tags, and description.",
            <span style={{ fontWeight: "400", color: "#121212" }}>
              Buy and Bid
            </span>,
            "The NFT marketplace platform should have a feature that allows users to buy and bid for NFTs listed on the platform. The bidding feature should include a bid expiration date and allow users to view details about the bid’s current status.",
            <span style={{ fontWeight: "400", color: "#121212" }}>Wallet</span>,
            "The NFT Marketplace Platform should have a wallet that allows users to store, send and receive non-fungible tokens. The easiest way to integrate this feature is to provide users with a connected wallet that they already use. For example, you can integrate the most popular wallets like Metamask, Coinbase, or any crypto wallets.",
          ],
          desc3: [
            "Solidity (Smart Contracts), React, Node js, NFT Standards, InterPlanetary File System (IPFS), Truffle, Ganache",
          ],
        },
      },
    ],
  },

  {
    img: "/assets/images/casestudies/aibased.svg",
    date: "December 2020",
    title: "AI Based Collision Avoidance System",
    service: ["Machine Learning & Artificial Intelligence"],
    industry: [],
    drilldown: [
      {
        img1: "/assets/images/casestudyinfo/aibased1.svg",
        img2: "/assets/images/casestudyinfo/aibased2.svg",
        desc: "To develop an AI-based fully autonomous collision avoidance system for unmanned vessels, Elimination of human error/intervention for safe navigation, Reduction of manpower, Reduction in cost, Obviate dependency on foreign OEMs for niche technology/spares/services and building expertise in niche technology",
        subTitle1: "Presented task",
        subtitle2: "Presented solution and Challenges",
        subtitle3_half: "Infrastructure and technologies used",
        subtitle3: "Product Features and Functionalities",
        subtitle5: "Cost Effectiveness",
        point: {
          desc1: [
            "The increasing density of ship traffic flow leads to the frequent occurrence of ship collision accidents. Ship collisions cause major losses in terms of property, equipment,and human lives. Therefore, more investigations should be focused on this problem,which mainly results from human error during ship control. As global economic activities become more interconnected, the density of maritime traffic flows is increasing, especially in inshore navigation and fishing areas. Clearly, this situation increases the risk of collisions between vessels. Ship collisions often result in significant casualties and economic damage. It has been estimated that human error is responsible for anywhere between 75% and 96% of all marine accidents. Some collisions are reportedly caused by crew members becoming too consumed with onboard instruments, meaning that they fail to look at what is happening on the seas outside",
            "Autonomous vessels are being introduced across the world by Naval forces for many security roles. These vessels undertake a monotonous job and need to navigate round the clock at sea without any incident/accident. If collision avoidance is introduced into such vessels, this will give great relief to crews on board from manning the bridge for 24X7 hours. Human error can be reduced while navigating vessels at sea and in the harbor. This autonomous system once developed, can be integrated into both manned and unmanned vessels. The AI system being developed should be capable of avoiding collisions and be trained to get away from accidents at sea or harbor. A collision/accident at sea would be disastrous for the Navy view loss of men/material or even the ship itself. The AI system being developed should be taught with the navigational rules at sea ie, (Rule of the Road-ROR). Once the collision avoidance system follows ROR, the ship will not commit any collision. There is a need to integrate the existing sensors/radars/AIS and navigational controls of ships into the AI system being developed to make the system fully autonomous.",
            "Even with strict regulations for the training and qualification of seafarers, many a time the crew gets panicked and forgets the duties during such emergencies So if we use AI then it takes comparatively less time than humans. And also humans get nervous in critical situations but machines will perform whatever action is required automatically.",
          ],
          desc2: [
            "The AI system being developed for collision avoidance at sea needs to be taught with existing maritime ROR as shown below. The sensors fitted on board can be used for locating obstructions around the vessels. AI system being developed needs to be integrated with the navigational radar and AIS of vessels to capture radar pictures and positions of other vessels online. Once the fellow vessels are identified, the probability of collision is determined by the system from their velocity and course. Once the collision probability is determined, the future course of the ship around is calculated. From the future course of vessels, the chances of collision is ascertained and accordingly, the ROR algorithm is applied to negotiate the situation. Change of course and throttle command is then transmitted to controls of the ship to avoid the collision in an autonomous mode or adequate warning can also be issued to the ship staff to the needful to avoid the collision.",
            "The system should be capable of detecting objects and avoiding collisions that are a threat to ships like other ships, huge waves, etc. What happens is on the top of the ship there will be radar. Using that radar we will be finding if any objects are present in that direction this is a traditional approach. The proposed AI system will use that radar if we get any signals from the surroundings then AI automatically captures the images in which direction we got the signal. Again we need to pass captured images to the algorithm then it will result in what kind of object is present and we are just capturing the image only when radar finds the signals. And in the traditional way ship’s visibility is poor because of fog, rain, or darkness. In that case, AI captures the image and does some filtration to get a clear view. While there is significant potential for AI to improve safety by reducing “human errors” in marine operations, there are also many aspects of the technology that suggests new hazards.",
          ],
          desc3_half: [
            "Radar Image capture, Signal Processing, Image enhancement, Image Processing, Vessel recognition, Target Recognition, Prediction-future position, Datacenter GPUs, Weak/Narrow AI, Reinforcement learning, ML-Supervisory learning on ROR, Library and data set creation on vessels, Python, Open CV, FastAPI, Keras & Tensorflow, PostgreSQL",
          ],
          desc3: [
            "The product being developed will be a Narrow AI-based solution to avoid collision courses and collisions with other vessels at sea. Once interfaced with the bridge navigation system and AIS, the vessels approaching the user’s vessel are determined along with their future course. ROR will be followed by an AI system to avoid collision and an adequate warning will be given to all concerned. Radar and AIS should provide images continuously to the AI system being developed to determine the movement of vessels around and to calculate the probability of collision with respect to the position and speed/course of the Naval vessel.",
          ],
          desc5: [
            "If the AI system is developed to take the output in the form of images from the AIS and radar systems on an online mode, the same images can be processed continuously too. As a certain the position of vessels/obstruction around and for determining the collision probability and corresponding avoidance. Here Navy can save a huge amount by not recreating dedicated sensors/radars for the developed AI-based anti-collision system. Capturing high-resolution screenshots of existing radar/lidar and ASI can be done. This approach will not incur any costs for prototyping.",
          ],
        },
      },
    ],
  },
];
export const newsimg = [
  {
    img: "/assets/images/newsevents/atlanta.svg",
    title: "September 2024",
    subTitle:
      "Atlanta, US based Optomi LLC and Data Template to collaborate and deliver technology services across various industries which includes insurance, retail, logistics etc.",
  },
  {
    img: "/assets/images/newsevents/krishnavhs.svg",
    title: "September 2024",
    subTitle:
      "We had the pleasure of engaging with Mr. Krishna VHS, Co-Founder and Head of Sales & Strategy from vBridge Hub, during his visit to our Data Template office in Bangalore.",
  },
  {
    img: "/assets/images/newsevents/vivekcariappa.svg",
    title: "August 2024",
    subTitle:
      "It was great to host Mr. Vivek Cariappa, Founder of Krac-A-Dawna at our Bangalore office.",
  },
  {
    img: "/assets/images/newsevents/koshidacorporation.svg",
    title: "August 2024",
    subTitle:
      "Koshida Corporation, Tokyo, Japan and Data Template to work together in providing IT solutions for iOT, Business Intelligence and Analytics.",
  },
  {
    img: "/assets/images/newsevents/kimaruai.svg",
    title: "August 2024",
    subTitle:
      "It was wonderful to catch up with the Kimaru.ai team in Tokyo, Japan. Data Template to further strengthen tie-up with Kimaru.ai to provide cutting edge, innovative AI enabled solutions across varied industries globally.",
  },
  {
    img: "/assets/images/newsevents/crystalventure.svg",
    title: "August 2024",
    subTitle:
      "Data Template to partner with Crystal Ventures Kigali, Rwanda to provide technology services in the field of IT infra, Cloud and Security.",
  },
];

export const eventsimg = [
  {
    img: "/assets/images/newsevents/hackathon.svg",
    title: "Hackathon 2024",
    subTitle:
      "It was an exciting and fun filled day at Data Template on our annual Hackathon event this week! Creativity, Imagination, Collaboration and Coding were seen expanding the horizon at the event.",
  },
  {
    img: "/assets/images/newsevents/independenceDay.svg",
    title: "Independence Day 2024",
    subTitle:
      "Celebrated Independence Day at our offices with creative desk decorations and vibrant tricolor attire! Proud of our team's spirit and unity.",
  },
  {
    img: "/assets/images/newsevents/mrandmsdt.svg",
    title: "Mr. & Ms. Data Template 2024",
    subTitle:
      "Step into the fun of yesterday's Mr. & Ms. Data Template event with these snapshots! It was a day of smiles, energy, and great teamwork. Check out the memories we made!",
  },
  {
    img: "/assets/images/newsevents/womenEmpowermentSession2024.svg",
    title: "Women Empowerment Session 2024",
    subTitle:
      "Empowered and inspired! Huge thanks to Dr. Priyanka Kiran for an incredible women empowerment session at our office yesterday!",
  },
];

export const testimonials_imgs = [
  {
    img: "/assets/images/Testimonials/DoriDanganhardt.svg",
    title: "Cheif Data Offier, Closing Corp",
    subTitle: "Dori Daganhardt",
  },
  {
    img: "/assets/images/Testimonials/Baldridge.svg",
    title: "Director of Research, The Feinberg School of Medicine",
    subTitle: "Abi Baldridge",
  },
  {
    img: "/assets/images/Testimonials/Dr.Ajay.svg",
    title: "Deputy Director & Head, Health System Uni",
    subTitle: "Dr. Ajay S Vamadevan",
  },
  {
    img: "/assets/images/Testimonials/SonaMahtani.svg",
    title: "Cheif Executive Selby Trust, London",
    subTitle: "Sona Mahtani",
  },
  {
    img: "/assets/images/Testimonials/Lorem(1).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(2).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(3).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(4).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(5).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(6).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(7).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
  {
    img: "/assets/images/Testimonials/Lorem(8).svg",
    title: "Lorem Ipsum",
    subTitle: "Lorem Ipsum",
  },
];
export const ai_imgs = [
  {
    id: "ethereum",
    img: "/assets/images/blog/ethereum.svg",
    title: "January 2025",
    subTitle: "Ethereum Network for Real-time Data Processing",
  },
  {
    id: "collaborative",
    img: "/assets/images/blog/Collaborative.svg",
    title: "October 2022 ",
    subTitle: "Collaborative Machine Learning",
  },
  {
    id: "augmentedintelligence",
    img: "/assets/images/blog/BusinessIntelligence.svg",
    title: "July 2022",
    subTitle:
      "Enhancing Business Performance with Augmented Business Intelligence",
  },
];

export const blog_imgs = [
  // {
  //   id: "chatbot",
  //   img: "/assets/images/blog/Chatbot.svg",
  //   title: "August 2024",
  //   subTitle: "Implementing Chatbot using Rasa Pro Framework ",
  // },
  {
    id: "ruleengine",
    img: "/assets/images/blog/ruleengine.svg",
    title: "November 2024",
    subTitle: "Rule Engine",
  },
  {
    id: "sqlquery",
    img: "/assets/images/blog/Sqlquery1.svg",
    title: "August 2024",
    subTitle: "Mastering SQL Query Optimization",
  },
  {
    id: "web",
    img: "/assets/images/blog/web.svg",
    title: "January 2023",
    subTitle: "Is Web 3.0 really worth the hype?",
  },
  {
    id: "hrteamops",
    img: "/assets/images/blog/hr.svg",
    title: "January 2023 ",
    subTitle: "HR : TeamOps Engineer",
  },
  {
    id: "gaming",
    img: "/assets/images/blog/Gaming.svg",
    title: "September 2022",
    subTitle:
      "Gaming Market to Generate $150 Billion in Dealmaking and Fundraising.",
  },
  {
    id: "enterprise",
    img: "/assets/images/blog/Enterprise.svg",
    title: "August 2022",
    subTitle: "The Enterprise AI Series: Products & Use Cases",
  },
  {
    id: "biometrics",
    img: "/assets/images/blog/Innovations.svg",
    title: "June 2022",
    subTitle:
      "Innovations in technology make our lives more secure, from biometrics to AI",
  },
  {
    id: "pycharm",
    img: "/assets/images/blog/Pycharm.svg",
    title: "May 2022",
    subTitle:
      "PYCHARM- The most powerful & user friendly IDE for python development",
  },
  {
    id: "blockchain",
    img: "/assets/images/blog/Blockchain.svg",
    title: "April 2022 ",
    subTitle: "How blockchain gaming industry is booming despite challenges",
  },
  {
    id: "cryptocurrency",
    img: "/assets/images/blog/Cryptocurrency.svg",
    title: "March 2022",
    subTitle: "Cryptocurrency Mining Malware Targets AWS Lambda ",
  },
];

export const benefits_imgs = [
  {
    img: "/assets/images/benefits/Flexible.svg",
    title: "Flexible work life",
  },
  {
    img: "/assets/images/benefits/Social.svg",
    title: "Social club",
  },
  {
    img: "/assets/images/benefits/Health.svg",
    title: "Health insurance",
  },
  {
    img: "/assets/images/benefits/Competitive.svg",
    title: "Competitive salary & bonuses",
  },
  {
    img: "/assets/images/benefits/Teaontap.svg",
    title: "Tea on tap",
  },
  {
    img: "/assets/images/benefits/Funatwork.svg",
    title: "Fun at work",
  },
  {
    img: "/assets/images/benefits/Skill.svg",
    title: "Skill upgrading programs",
  },
  {
    img: "/assets/images/benefits/Inter.svg",
    title: "Inter/Intra social get together",
  },
  {
    img: "/assets/images/benefits/Niche.svg",
    title: "Hands on experience on niche technology",
  },
  {
    img: "/assets/images/benefits/Empowerment.svg",
    title: "Focused on employee empowerment",
  },
  {
    img: "/assets/images/benefits/Crossplatform.svg",
    title: "Cross platform training opportunity",
  },
];

export const westandtogether_imgs = [
  {
    img: "/assets/images/westandtogether/westandtogether.svg",
    title: "We Stand Together Against Hate",
    subTitle:
      "We stand together against hate directed at someone on our office network because of race, religion, sexual orientation, disability or gender identity.",
  },
  {
    img: "/assets/images/westandtogether/womencouncil.svg",
    title: "Women Council",
    subTitle:
      "The concept of Women Council is a broad commitment to change every aspect of life so as to create a more harmonious and happy life for women. We continuously measure and act on progress to create gender equality. Gender equality is a basic human right, and it is also fundamental to having a peaceful, prosperous world.",
  },
  {
    img: "/assets/images/westandtogether/diversity.svg",
    title: "Diversity & Leadership Programmes",
    subTitle:
      "The Diversity in Leadership programme aims to challenge bias, remove obstacles to attaining leadership roles and to use tools to build confidence in aspiring leaders. It is aimed at aspiring leaders, senior leaders, managers and governors. We work closely with People Element to identify & mentor our leaders.",
  },
  {
    img: "/assets/images/westandtogether/hunger.svg",
    title: "Hunger & Education",
    subTitle:
      "As a progressive company, we strongly believe in our social commitments. We are proud to closely work with Karunya Bangalore, a non-profit organization with a vision to serve the poor and preserve humanity.",
  },
  {
    img: "/assets/images/westandtogether/iamwhat.svg",
    title: "I am What I am",
    subTitle:
      "We always foster workplace equality, let it be their sexual orientation or gender identity. We have proudly included sexual orientation and gender identity in our non-discrimination policies as a way to have a public commitment to hiring more lesbian, gay, bisexual, transgender, and queer (LGBTQ) employees.",
  },
  {
    img: "/assets/images/westandtogether/health.svg",
    title: "Health & Wellbeing",
    subTitle:
      "We have built a culture of holistic well-being that includes physical, emotional, financial, social, career, community, and purpose. At the heart of this is the growing need for flexibility in where, when, and how employees work.",
  },
];

export const StratergyAlliences = [
  {
    id: "envizage",
    img: "/assets/images/strategicalliance/Envizage.svg",
    title: "Transforming financial futures",
    subTitle: "Envizage",
  },
  {
    id: "indigital",
    img: "/assets/images/strategicalliance/indigital.svg",
    title: "Leading edge digital transformation",
    subTitle: "INDIGITAL Inc",
  },
  {
    id: "yourgrantagency",
    img: "/assets/images/strategicalliance/yourGrantAgency.svg",
    title: "Matching SMEs with the right grants",
    subTitle: "YourGrant Agency",
  },
  {
    id: "whiteboard",
    img: "/assets/images/strategicalliance/whiteboard.svg",
    title: "Connect, collaborate & share",
    subTitle: "Whiteboard.chat",
  },
  {
    id: "intersystems",
    img: "/assets/images/strategicalliance/intersystem.svg",
    title: "Creative data technology ",
    subTitle: "InterSystems",
  },
  {
    id: "yellowpage",
    img: "/assets/images/strategicalliance/yellowpage.svg",
    title: "Proudly Kazakhstan’s B2B information system",
    subTitle: "Yellow Pages Kazakhstan",
  },
  {
    id: "ozone",
    img: "/assets/images/strategicalliance/ozone.svg",
    title: "Enterprise solution for manufacturing",
    subTitle: "Ozone",
  },
  {
    id: "astra",
    img: "/assets/images/strategicalliance/astra.svg",
    title: "Aerospace and Defence",
    subTitle: "Astra",
  },
  {
    id: "clinally",
    img: "/assets/images/strategicalliance/clinally.svg",
    title: "AI powered healthcare system ",
    subTitle: "ClinAlly",
  },
  // {
  //   id:'cybershelter',
  //   img: '/assets/images/strategicalliance/cybershelter.svg',
  //   title: 'Security & reliability assured',
  //   subTitle: "CyberShelter"
  // }
];

export const certification_imgs = [
  {
    img: "assets/images/certification/certification1.svg",
  },
  {
    img: "assets/images/certification/certification2.svg",
  },
  {
    img: "assets/images/certification/certification3.svg",
  },
  {
    img: "assets/images/certification/certification4.svg",
  },
];

// export const MA_imgs = [{
//   img: 'assets/images/Certification_imgs/MA(1).svg'
// },
// {
//   img: 'assets/images/Certification_imgs/MA(2).svg'
// },
// {
//   img: 'assets/images/Certification_imgs/MA(3).svg'
// }
// ]

export const ourteam = [
  {
    id: "founder",
    img: "/assets/images/ourteam/akbar.svg",
    title: "Technical Lead Opensource",
    subTitle: "Akbar Ali",
    subTitle2:
      "Data Template has been very deliberate about understanding my professional goals and working with me to reach them. I have been with the company for almost 4 years, and I had tremendous personal and professional growth during this time.",
  },
  {
    id: "finance",
    img: "/assets/images/ourteam/sajin.svg",
    title: "Associate Architect Technology Dot Net",
    subTitle: "Sajin Sivanandhan",
    subTitle2:
      "My overall experience with the company through the last 5 years working there have been great. The best part about leadership at Data Template is the ability to not only help achieve the company goals but my own goals as well. I’ve been able to grow professionally and personally and have developed meaningful friendships and relationships.",
  },

  {
    id: "tech",
    img: "/assets/images/ourteam/bdteams.svg",
    title: "Business Development",
    subTitle: "Kimi, Anjali, Dipika & Supriya",
    subTitle2:
      "The BD team focuses on creating long-term value for the company. It helps the company to grow and develop plausibly and beneficially. They are the face of the company and help in developing the business. We at Data Template are delighted to have all of you on board with us.",
  },
  {
    id: "techno",
    img: "/assets/images/ourteam/hrrecruitersupdate.svg",
    title: "HR Recruitment",
    subTitle: "Suhali, Apurva Kumari & Patil Laxmi Mallikarjun",
    subTitle2:
      "The recruitment team benefits the company by accomplishing the set targets and fostering team unity and understanding. The recruitment team seeks to find and hire candidates for any specific position required by the company. They find the right person for the right profile! The Data Template family appreciates your hard work.",
  },
];

export const LocationData = [
  {
    country: "Bangalore",
  },
  {
    country: "Cincinnati",
  },
  {
    country: "Mangalore",
  },
  {
    country: "London",
  },
  {
    country: "Almaty",
  },
  {
    country: "Dhaka",
  },
  {
    country: "Vilnius",
  },
  {
    country: "Kyiv",
  },
];

export const wpData = [
  {
    envUrl: "https://www.datatemplate.com/",
    blogLink: "http://65.1.194.170/category/blog/",
    postsLink: "http://65.1.194.170/wp-json/wp/v2/posts?_embed&per_page=100",
    categoriesLink:
      "http://65.1.194.170/index.php?rest_route=/wp/v2/categories",
    contact: "http://65.1.194.170/contact.php",
    career: "http://65.1.194.170/career.php",
    news: "http://65.1.194.170/news.php",
    blogApi:
      "https://www.googleapis.com/blogger/v3/blogs/1881004714709103954/posts?maxResults=50&key=AIzaSyBgEPFAuNxd2pcIf2rKWO22AEUwVPWJ57o",
  },
];
export const aiconsultingimgs = [
  {
    id: 1,
    img: "/assets/images/aiservicescasestudyinfo/enterpriseautomated.svg",
    date: "September 2024",
    title: "Enterprise Automated Communication Solution",
    service: [],
    industry: ["Travel & Transport Logistics"],
    drilldown: [
      {
        img1: "/assets/images/aiservicescasestudyinfo/enterpriseautomated.svg",
        desc: [
          "Develop an analytical system that scrapes and interprets text from web pages and PDFs, converts this information into embeddings for storage in a vector database, and utilizes a chatbot integrated with a semantic search API to provide specific responses in the banking and healthcare sectors.",
          "Developed and deployed an AI Chatbot using LLM’s and word embeddings ,which process the user queries and promptly provides accurate and timely information. The detailed process on how the objective was achieved is as follows.",
        ],
        subTitle1: "Solution Benefits",
        subtitle2: "Technology",
        point: {
          desc1: [
            "Automated Content Extraction: Streamlines the process of converting web content and PDFs into digital format, saving users time and effort by automating data retrieval and storage.",
            "Enhanced Data Understanding: Utilizes advanced embedding techniques and semantic search to analyze and interpret complex banking and healthcare information, providing users with clear, actionable insights.",
            "Instant Responses: Offers real-time answers to user inquiries, drastically reducing wait times compared to conventional research or customer support channels.",
            "Accuracy and Relevance: Minimizes the spread of outdated or incorrect information by sourcing data directly from relevant documents, ensuring users receive the most current and accurate answers.",
            "User-Centric Experience: Features an intuitive user interface that simplifies the search for specific banking and healthcare information, enhancing user satisfaction and engagement.",
            "Round-the-Clock Availability: Provides constant access to information, enabling users to find answers to their questions anytime, enhancing convenience and user satisfaction.",
            "Personalized Responses: Adapts answers to each user's unique query by analyzing their questions in the context of the vast data stored in the vector database, ensuring highly relevant and customized information.",
            "Scalable Solution: Designed to efficiently handle growing numbers of users and queries without significant increases in cost or decreases in performance.",
            "Informed Decision-Making: Empowers users by providing them with comprehensive insights into banking and healthcare questions, aiding them in making well-informed decisions.",
            "Educational Impact: Enhances users' understanding of complex topics in banking and healthcare, contributing to improved financial and health literacy.",
          ],
          desc2: [
            "Dolly 2.0",
            "Llama-2",
            "Google Palm",
            "ReactJS",
            "PyCharm",
            "Chroma DB",
            "Python",
            "Flask",
            "VS Code",
          ],
        },
      },
    ],
  },
];
